import React from 'react';
import Footer from './Footer';
const TermsOfUse = () => {
    return (
      <div className='terms-wrap'>
      <div className='terms'>
        <h2>Terms of Use</h2>
        <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern our relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
  
        <h3>Terms and Conditions</h3>
  
        <ul>
          <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
          <li>This website may use cookies or other forms of information storage to monitor browsing preferences.</li>
          <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
          <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
          <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
          <li>From time to time, this website may also include links to other websites or embeded videos. These links and videos are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s) or embeded videos.</li>
          <li>The videos featured on this site are for informational and educational purposes. The creators of these videos are not affiliated with nor have they endorsed the content of this website. All rights and content for the videos are owned by their respective creators. If you have any concerns regarding the content featured here, please contact us.</li>
          <li>The Masculine Reset may revise these terms of use for its website/platform at any time without notice.</li>
          <li>The materials on The Masculine Reset's website/platform are provided on an 'as is' basis. The Masculine Reset makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, The Masculine Reset does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website/platform or otherwise relating to such materials or on any sites linked to this site.</li>
          <li>In no event shall The Masculine Reset or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on The Masculine Reset's website/platform, even if The Masculine Reset or a The Masculine Reset authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</li>
          <li>The materials appearing on The Masculine Reset's website/platform could include technical, typographical, or photographic errors. The Masculine Reset does not warrant that any of the materials on its website/platform are accurate, complete, or current. The Masculine Reset may make changes to the materials contained on its website/platform at any time without notice. The Masculine Reset does not, however, make any commitment to update the materials.</li>
          <li>Please note that this website may include content such as YouTube videos and links to external sources that are not owned by The Masculine Reset</li>
          <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of the United States of America.</li>
        </ul>
  
        <p>By using this website, you agree to the above terms and conditions of use.</p>
      </div>
      <Footer />

      </div>
    );
  };
  
  export default TermsOfUse;