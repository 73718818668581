import React from 'react';
import image from '../img/logo/TMR_LOGO1.png';
import YoutubeEmbed from '../components/YoutubeEmbed';
import Button from '../components/buttons/Button';
import ScrollDown from '../components/Scrolldown';
import StartJourney from '../components/StartJourney';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
<head>
<meta name="description" content='Experience Masculine Reset - Reconnect with your masculinity, fulfill your potential, and find your true purpose. Be the man you were meant to be.' />
<meta property="og:image" content="https://themasculinereset.com/img/logo/TMR_logo_ws.png" />
</head>
function Home() {
  return (
    <div className='All-Wrap-home'>
      <div id='hero'>
        <div id='hero_padding'>
          <h1 className="App-head huge-text">The Masculine Reset</h1>
          <h4 className='hero-vision'>Reclaim your purpose and become the man you were meant to be.</h4>
          <p className='hero-text'>Take control of your life with the knowledge and proven methods used by great men. Develop the strength, character, and wisdom needed to be one of them.</p>
          <Link to='/thepath' onClick={() => window.scrollTo(0, 0)} id='RMM_CTA'><Button id='scroll_down' label="Unlock My Full Potential" className="hero_button" /></Link>
        </div>
        <div id='sd_container'>
          <ScrollDown />
        </div>
        <div id="sep_div" className='sep_div'><h2>Start Fulfilling Your Potential</h2></div>
      </div>
      
      <section className='section-container whytmrsection'>
        <div className='mobile-adjust'>
          <hr className='hr'></hr>
          <div className="tired_text">
            <div className='tired-text-container'>
              <h3 className='tired-text-txt'>Are you tired of </h3><h3 className='below-capabilities'>&nbsp;living below your capabilities?</h3>
            </div>
            <div className="second_font" style={{ textAlign: 'center', paddingBottom: '8px', fontSize: '16px' }}>It's time to <span className="second_font" style={{ textDecorationThickness: '1px', textDecorationLine: 'none', textDecorationColor: 'red' }}>reject mediocrity</span> and reclaim... </div>
          </div>
          <hr className='hr2'></hr>
          <h5 className="alpha_text" style={{ padding: '100px 0px 100px', color: 'var(--primary_color)', textDecorationThickness: '2px', textDecorationLine: 'underline', textDecorationColor: '#000000', textUnderlineOffset: '2px' }}>Your FULL potential.</h5>
        </div>
        <YoutubeEmbed id="Youtube_home" embedId="XnJa6tK2ack" width="650px" height="400px" />
        <section className='lesson-content whytmr'>
  <div className='mobile-adjust2'>
    <h2 className='section-heading'>Why Join The Masculine Reset?</h2>
    <p className='section-text'>
      At The Masculine Reset, we understand the unique challenges that modern men face in today's rapidly changing world. Our mission is to empower you with the knowledge and tools you need to reignite your sense of purpose and embrace genuine masculinity.
    </p>
    <p className='section-text'>
      Here's why you should consider becoming part of our community:
    </p>
    <ul className='section-list'>
      <li>
        <strong>Time-Tested Wisdom:</strong> Our approach is grounded in the timeless wisdom and experiences of great men throughout history. We distill their insights into actionable principles that resonate with contemporary life.
      </li>
      <li>
        <strong>Personalized Support:</strong> We provide tailored guidance and unwavering support on your personal journey of self-improvement and holistic growth. Your path to self-discovery is unique, and we're here to help you navigate it.
      </li>
      <li>
        <strong>Brotherhood and Camaraderie:</strong> Join a dynamic community of like-minded individuals who share your commitment to becoming the best versions of themselves. Together, we foster an environment of mutual support and camaraderie.
      </li>
      <li>
        <strong>A Wealth of Resources:</strong> Access a vast array of resources, including thought-provoking articles, inspiring videos, and comprehensive courses. These resources are carefully curated to aid you in your transformative journey.
      </li>
      <li>
        <strong>Tangible Results:</strong> The methods we utilize have empowered numerous men to achieve substantial and enduring improvements in various aspects of their lives. The results are tangible, and the transformations are real.
      </li>
    </ul>
  </div>
</section>

        <div className="video-container">
          <video src="your-video-url.mp4" autoPlay loop />
        </div>
        <img style={{ display: 'none' }} className="TMR_img" src={image} alt="staring into the sunset" width="30%" height="auto"></img>
      </section>
      <div className='SJ_margin-adjust'>
      <div id='sj_container'>
        <StartJourney />
      </div>
      </div>

      <Footer />
      <style>{`
  .logo {
    margin: auto 1rem;
  }

  .video-container {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`}</style>

    </div>
  )
}

let count = 0;
runScript()


  function runScript() {
    const div = document.getElementById('sep_div');
    while (!div && count < 5) {
      count++;
      setTimeout(runScript, 1000);
      return;
    }
  
    if (!div) {
      return;
    }

    if (window.screen.width > 800) {
    window.addEventListener('scroll', function(){
      let scrollPos = window.scrollY;
  
      div.style.transition = "transform 0.5s ease";
  
      if (scrollPos >= 100) {
        div.style.transform = "translate(50%, 50%)";
      } else {
        div.style.transform = "translate(50%, 100%)";
      }
    });
  }
}
console.log(window.innerWidth)
  if (window.screen.width
    > 50) {
    window.addEventListener('scroll', function() {
      const div = document.getElementById('sep_div');
      let count = 0;
      console.log(window.innerWidth)

      while (!div && count < 5) {
        count++;
        setTimeout(runScript, 1000);
        return;
      }
  
      if (!div) {
        return;
      }
  
      let scrollPos = window.scrollY;
  
      div.style.transition = "transform 0.5s ease";
  
      if (scrollPos >= 100) {
        div.style.transform = "translate(50%, 50%)";
      } else {
        div.style.transform = "translate(50%, 100%)";
      }
    });
  }
  
  
  window.addEventListener('scroll', function() {
    let scrollPos = window.scrollY;
    let alphaText = document.querySelector('.alpha_text');
    console.log(scrollPos)

    if (!alphaText) {
      return;
    }
    
    alphaText.style.transition = "font-size .8s ease";

    if (window.innerWidth > 500) {
      if (scrollPos >= 1600) {
        alphaText.classList.add('grown');
      } else {
        alphaText.classList.remove('grown');
      }
    } else {
      if (scrollPos >= 1200) {
        alphaText.classList.add('grown');
      } else {
        alphaText.classList.remove('grown');
      }
    }
  });


  window.addEventListener('scroll', function() {
    let scrollPos = window.scrollY;
    let tiredText = document.querySelector('.tired_text');
    
    if (!tiredText) {
      return;
    }
    
    tiredText.style.transition = "transform 0.5s ease";
    tiredText.style.transform = "translate(-100%, 0%)";
    
    if (scrollPos >= 600) {
      tiredText.style.transform = "translate(0%, 0%)";
    }
  });



export default Home