import React, { Component } from 'react';
import axios from 'axios';

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      successMessage: null,
      errorMessage: null,
    };
  }

  // Handle form submission
  handleSubmit(event) {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = this.state;

    console.log('Submitting password change request...');
    
    // Make an AJAX request to change the password
    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/changepassword`, { oldPassword: currentPassword, newPassword, confirmPassword })
    .then((response) => {
      console.log('Password change successful');
      console.log('Response data:', response.data);

      this.setState({ successMessage: response.data.message, errorMessage: null });

      setTimeout(() => {
        window.location.href = '/thepath';
      }, 2500);
    })
    .catch((error) => {
      console.error('Password change failed:', error);
      console.log('Error response data:', error.response.data);

      this.setState({ successMessage: null, errorMessage: error.response.data.message });
    });
  }

  // Handle changes to the current password input
  handleCurrentPasswordChange(event) {
    this.setState({ currentPassword: event.target.value });
  }

  // Handle changes to the new password input
  handleNewPasswordChange(event) {
    this.setState({ newPassword: event.target.value });
  }

  // Handle changes to the confirm password input
  handleConfirmPasswordChange(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  render() {
    // Render a message based on the successMessage and errorMessage state properties
    const loggedInUser = localStorage.getItem('loggedInUser');
    console.log('Logged in user:', loggedInUser);

    if (!loggedInUser) {
      console.log('No user logged in');
      return <div style={{ paddingTop: '210px' }}>You must be logged in to change your password.</div>;
    }

    let message;
    if (this.state.successMessage) {
      message = <div className="alert alert-success">{this.state.successMessage}</div>;
    }
    if (this.state.errorMessage) {
      message = <div className="alert alert-danger">{this.state.errorMessage}</div>;
    }

    return (
      <div className="All-Wrap" style={{ height: '100vh' }}>
        <div className="signupLogin-wrap" style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%, -50%)' }}>
          <form className="form-wrap" onSubmit={(event) => this.handleSubmit(event)}>
            <h1 style={{ fontSize: '32px', margin: '0px' }}>Change Password</h1>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
              <div className="form-group">
                <label htmlFor="currentPassword">Current Password</label>
                <input type="password" className="form-control" id="currentPassword" placeholder="Current Password" value={this.state.currentPassword} onChange={(event) => this.handleCurrentPasswordChange(event)} />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <input type="password" className="form-control" id="newPassword" placeholder="New Password" value={this.state.newPassword} onChange={(event) => this.handleNewPasswordChange(event)} />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={(event) => this.handleConfirmPasswordChange(event)} />
              </div>
              <button type="submit" className="btn btn-primary submit-button">Change Password</button>

              {message}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default PasswordChangeForm;
