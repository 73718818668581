import React, {useEffect, useState} from 'react';
import image from '../img/statue.png'
import MenuLessons from '../components/Menu_lessons';
import Stripe from '../components/Stripe/Stripe';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { enc, AES } from 'crypto-js';
import Footer from '../components/Footer';

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');

  
  // Function to decrypt data
  function decryptData(data) {
    const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
    return decrypted;
  }
  

  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');

  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;

  const categories = {
    introduction: ['Getting the Most out of This Course', 'Joining a Community of Men Who Share Your Goals and Values'],
       awaken: ['The Crisis of Masculinity: Exploring the Challenges Facing Men Today', 'The Necessity of Reclaiming Masculinity', 'Recognizing how Men Are Taught to Drift through Life', 'An Honest Look at Yourself', 'Setting the Foundation for Growth'],
      aim: ['Overcoming Procrastination: Building Momentum and Productivity', 'Identifying Your Why: Finding Your Motivation', 'Creating a Personal Mission Statement: Aligning Your Goals with Your Purpose', 'The Power of Dopamine: Feeling Motivated', 'Developing a Positive Mental Attitude', 'Understanding the Different Types of Masculine Men', 'Defining Your Masculine Identity',  'Creating Your Vision of Masculine Excellence',    'Achieving Success through Persistence'],
      build: [
        'Establishing Healthy Habits',
        'Improving Physical Health and Wellness',
        'Developing Mental Toughness',
        'Building Emotional Intelligence',
        'Improving Communication Skills',
        'Cultivating Relationships and Connections',
        'Mastering Time Management',
        'Effective Communication Skills',
        'Enhancing Decision Making Skills',
        'Stress Management and Resilience',
        'Goal Setting and Achievement',
        'Conflict Resolution and Effective Communication',
        'Building Confidence and Self Esteem',
        'Leadership Skills Development',
        'Building Resilient Relationships'
      ],
      empower: ['Fostering a Growth Mindset', 'Identifying and Challenging Limiting Beliefs', 'Practicing Self Care and Self Love','Mindfulness and Meditation', 'Personal Branding and Image', 'Developing a Growth Oriented Attitude','Building a Support System for Growth and Empowerment', 'Empowering Others and Creating a Positive Impact'],
      reflect: ['Assessing Personal Growth and Progress','Evaluating Current Habits and Goals','Adjusting and Refining Habits', 'Refining Goals and Objectives', 'Celebrating Successes and Learning From Failures', 'Preparing for The Next Phase Of Growth'],
      master: ['Achieving Mastery Of Self Discipline', 'Maintaining Control Over Thoughts, Emotions, and Actions', 'Developing A Strong Sense Of Personal Identity', 'Improving Leadership Skills', 'Living With Purpose and Meaning', 'Inspiring and Guiding Others'],
      actualize:['Living A Fulfilling Life','Pursuing Personal Passions and Interests', 'Achieving Personal and Professional Success', 'Making A Positive Impact On Society', 'Continuing To Grow and Evolve','Becoming A Role Model and Mentor for Others'],
  };

function ThePath() {
  
  function scrollToElement() {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }
  const [isPremium, setIsPremium] = useState(false);


  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/isUserPremium`, { params: { email: decryptedEmail } });
      setIsPremium(response.data.isPremium);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (decryptedEmail) {
      fetchUserData();
    }// eslint-disable-next-line
  }, [decryptedEmail]);


  useEffect(() => {
    scrollToElement();
  }, []);
    return(
      <div>
        <div className='All-Wrap'>
            <div className='lesson_container'>
         <img src= {image} alt="staring into the sunset" id='tp_image'></img>
        <h2 style={{color:'var(--secondary_color)', padding: '24px 0px'}}>The Path.</h2>
        <div className='potential-text'>
        <h5 style={{width: '100%', textAlign: 'left'}}>{decryptedUsername && `${decryptedUsername}, it's time to achieve your full potential.`} {!decryptedUsername && `Achieving your full potential begins here.`}</h5>
        <p style={{width: '100%'}}>Dedicate yourself to the curriculum designed to help you grasp and excel in the vital skills needed for a thriving, well-rounded individual. Set out on a path towards personal development, self-enhancement, and the life you've always envisioned. Start laying the groundwork to unlock your potential today.</p>
        </div>
        <MenuLessons categories={categories.introduction} title="Introduction" description="- How to be successful in this course. " id='0' />
        <MenuLessons categories={categories.awaken} title="Awaken" description="- Recognition of inadequacy, desire for change." id='1'/>
        {!isPremium && <h5 className='banner'><span className='unbold'>Get early access to all Premium content </span> <span className='bottom'>- 60% off for a limited time!</span></h5>}
        <div className='getPremiumUIContainer'>
        {loggedInEmail ? (
  isPremium ? (
    <h5 className='mobileCenter' >Thanks for being a premium member!</h5>
  ) : (
    <div className='getPremiumUI'>
      <div>
        <h5 className='mobileCenter'>Commit today to achieving your full potential!</h5>
        <p className='mobileCenter' style={{maxWidth: "450px"}}>Complete access to all present and soon to come TMReset premium content. </p>
      </div>
      <Link  to="/getpremium" className='mobileMargin16Top'><button className='btn button fullWidth '>Get Premium 60% off </button></Link>
    </div>
  )
) : (
  <div className='getPremiumUI' >
    <div className='fullWidth'>
      <h5 className='mobileCenter'>Sign up to start achieving your full potential!</h5>
      <p className='mobileCenter' style={{maxWidth: "450px"}}>Become the best version of yourself by learning how great men achieve success. </p>
    </div>
    <Link to="/api/signup"  className='mobileMargin16Top center rightAlignDesktop fit-content'><button className='btn button  fit-content no-wrap' >Sign Up </button></Link>
  </div>
)}

    </div>
        <MenuLessons categories={categories.aim} title="Aim" description="- Determine the man you want to be and how you'll become him." id='2'/>
        <MenuLessons categories={categories.build} title="Build" description="- Establish optimal habits and mindset to achieve success." id='3'/>
        <MenuLessons categories={categories.empower} title="Empower" description="- Develop self-empowerment through intentional actions." id='4'/>
        <h5 class='banner'>Reflect Release Date: December 2023</h5>

        <MenuLessons categories={categories.reflect} title="Reflect" description="- Evaluate progress, making necessary adjustments." id='5'/>
        <h5 class='banner'>Master Release Date: January 2024</h5>

        <MenuLessons categories={categories.master} title="Master" description="- Control over thoughts, emotions, and actions." id='6'/>
        <MenuLessons categories={categories.actualize} title="Actualize" description="- Live a meaningful, purposeful life." id='7'/>

      </div>

      </div>
            <Footer />
            </div>

    )
    
   
  
}









export default ThePath