import React from 'react';
import { Link } from 'react-router-dom';
import MarkCompleteButton from '../components/buttons/MarkCompleteButton';
import NextLessonButton from '../components/buttons/NextLessonButton';
import Lessons from '../components/Lessons';
import { enc, AES } from 'crypto-js';
import Footer from '../components/Footer';

const encryptionKey = process.env.REACT_APP_SECRET_KEY;

function decryptData(data) {
    const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
    return decrypted;
  }


function LessonPage() {

    let loggedInUser = localStorage.getItem('loggedInUser');
let loggedInEmail = localStorage.getItem('loggedInEmail');

const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

loggedInUser = decryptedUsername;
loggedInEmail = decryptedEmail;


    const path = window.location.pathname;
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });

    return (
        <div>
            <Lessons />
            <div className='button_container_center'>
            <div className="button_container"> 
            <Link to='/thepath' onClick={() => window.scrollTo(0, 0)} className='fill_height_width'><button  className="btn button link_button">Back to The Path</button></Link>
            <MarkCompleteButton path={path} loggedInEmail={loggedInEmail} />
            <NextLessonButton />
            </div>
            </div>
            <Footer />

        </div>
    );
}
window.dispatchEvent(new Event('scroll'));

export default LessonPage;
