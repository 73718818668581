import React from 'react';
import TermsOfUse from '../components/Terms_of_use';
import Footer from '../components/Footer';

function Terms_Of_Use() {
    return(
        <TermsOfUse />
    )
}

export default Terms_Of_Use