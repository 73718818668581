import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { enc, AES } from 'crypto-js';


const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');


  
  // Function to decrypt data
  function decryptData(data) {
    const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
    return decrypted;
  }

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');
  
  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  
  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;
  
  

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/create-payment-intent`, {
      method: "POST",
      body: JSON.stringify({}),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  function handleLogin() {
    localStorage.setItem('prevUrl', window.location.pathname); // Store the current URL as prevUrl
    // ...
  }

  return (
     <>
           <p style={{textAlign: "center", marginBottom: "0px", marginTop: "60px"}}>Unlock all TMReset content so you can</p>
      <h4 style={{textAlign: "center", margin: "12px 0px"}}>Become the man you deserve to be</h4>
      




      {loggedInEmail ? (
        clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )
      ) : (
        <div className="premium_login_container">
        <Link to="/api/login" onClick={handleLogin}>
        <button className="btn button premium_login_btn">
                  Log In to Purchase
        </button>
        </Link>
        
        </div>
      )}
      

            <h4 className="sale_banner" style={{textAlign: "center", marginTop: "40px"}}>60% off Early Access sale!</h4>
           
<div className="mobile-wrap limited-offer-david" style={{textAlign: "center", marginBottom: "0px", marginTop: "60px"}}><p>INCLUDES LIMITED TIME OFFER:</p> 
      
      <ul style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingLeft: "0px"}}>
      <li className="includedItems" style={{display: "flex", justifyContent: "center", marginBottom: "16px"}}>
  <b style={{fontSize: "14px", display: "flex", justifyContent: "center"}}>1:1 life coaching with David</b>{" "}
  <a
    className="x-logo"
    href="https://twitter.com/MasculineReset"
    target="_blank"
    rel="noopener noreferrer"
    style={{color: "blue", paddingLeft: "16px"}}
  ><b style={{fontSize: "14px", whiteSpace: "nowrap", padding: "0px 8px"}}>
    <u>X Profile</u>
    </b>
  </a>
</li>

      <li className="includedItems" style={{marginBottom: "16px", textAlign: "center"}}><b style={{fontSize: "14px"}}>1 Free Video and/or Phone Session</b></li>
      <li  className="includedItems" style={{marginBottom: "16px", textAlign: "center"}}><b style={{fontSize: "14px"}}>1 month of priority chat through discord</b></li>
      </ul>
      </div>
    </>
    
  );
}



export default Payment;