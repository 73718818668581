import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Spinner from "../Spinner";
import { enc, AES } from 'crypto-js';


const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');


// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}


export default function CheckoutForm() {
    const [amount, setAmount] = useState(0);    
    const [dollarAmount, setDollarAmount] = useState("$0.00");

    let loggedInUser = localStorage.getItem('loggedInUser');
    let loggedInEmail = localStorage.getItem('loggedInEmail');
    const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
    const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
    loggedInUser = decryptedUsername;
    loggedInEmail = decryptedEmail;
  

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchAmount = async () => {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/get-price`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      });
      const data = await response.json();
      const unitAmount = data.price;

      
      setAmount(unitAmount);
      setIsLoading(false); // Once the data is fetched, set isLoading to false

    };
    fetchAmount();
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    setIsProcessing(true);
  
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      
    });
  
    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }
  
    try {
      if (!paymentIntent) {
        throw new Error("Payment failed");
      }
  
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/premium`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: paymentIntent.payment_method,
          decryptedEmail: decryptedEmail, // pass decrypted email
        }),
      });
  
      const data = await response.json();
      if (data.message === "Payment successful") {
        window.alert(`Payment successful! Your commitment to achieving your full potential begins now, ${loggedInUser}`);
        setMessage(data.message);
        window.location.href = "/thepath"; // redirect to success page
      } else {
        setMessage("An unexpected error occured.");
      }      
    } catch (err) {
      console.log(err);
      setMessage("An unexpected error occured.");
    }
    
  
    setIsProcessing(false);
  };
  

  return (
    <div className="All-Wrap">
    {isLoading ? ( // Check if isLoading is true
    <div style={{height: "200px", width: "600px"}}>
    <Spinner /> 
    </div>
    ) : (
    <form id="payment-form" onSubmit={handleSubmit}>
    <PaymentElement id="payment-element" />
    <button
    className="stripe_btn"
    disabled={isProcessing || !stripe || !elements}
    id="submit"
    >
    <span id="button-text">
    {isProcessing ? "Processing ... " : `Purchase for $${amount}`}
    </span>
    </button>
    {/* Show any error or success messages */}
    {message && <div id="payment-message">{message}</div>}
    </form>
    )}
    </div>
    );
    }
