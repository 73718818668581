import React from 'react';

const Footer = () => {
  const footerStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const containerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const logoStyles = {
    marginBottom: '8px',
    display: 'block',
    width: '150px',
  };

  const rightsStyles = {
    margin: '8px 0px',
  };

  const linkStyles = {
    color: 'black',
  };

  return (
    <div id='outer-footer-container'>
    <div id="footer">
      <div className="footerContainer">
        <div className="footer-logo-container">
          <a href="/"><img style={logoStyles} src="./img/logo/TMR_logo.png" alt="TMR Logo" /></a>
          <span style={rightsStyles}>© 2023 All rights reserved.</span>
          <a className="tou_link" href="/terms_of_use"><span style={linkStyles}>Terms of Use</span></a>
        </div>
        <div id="mailchimp_signup" style={footerStyles}>
          <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
          <style type="text/css">
            {`
              #mc_embed_signup {
                background: #fff;
                clear: left;
                font: 14px Helvetica, Arial, sans-serif;
                max-width: 600px;
                margin: auto;
              }
            `}
          </style>
          <div id="mc_embed_signup" style={{ width: '100%', margin: '0px' }}>
            <form
              action="https://themasculinereset.us21.list-manage.com/subscribe/post?u=5831c9c1facd435d9e0595cfe&amp;id=134a6d3153&amp;f_id=0083eae1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
              style={{ margin: '0px' }}
            >
              <div id="mc_embed_signup_scroll">
                <h5 style={{ display: 'inline-block', fontWeight: 600, margin: '8px 0px' }}>Subscribe to TMReset Newsletter</h5>
                <p style={{ margin: '8px 0px', display: 'inline-block', fontWeight: 400, marginTop: '8px' }}>
                  Complete your commitment to reclaiming your masculinity and join men committed to achieving their full potential!
                </p>
                <div className="mc-field-group">
                  <label id="mce-email2" htmlFor="mce-EMAIL" style={{ display: 'none', fontSize: '12px' }}>
                    Email Address<span style={{ top: '3px' }} className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    placeholder="Email Address"
                    required
                    style={{ color: 'black', display: 'block', top: '0px' }}
                  />
                  <div className="optionalParent">
                    <div className="clear foot">
                      <input
                        style={{ margin: '0px', borderRadius: '4px' }}
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      />
                    </div>
                  </div>
                  <span
                    style={{
                      height: '31px',
                      position: 'relative',
                      zIndex: -3,
                      top: '-40px',
                    }}
                    id="mce-EMAIL-HELPERTEXT"
                    className="helper_text"
                  ></span>
                </div>
                <div className="clear foot">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input
                    type="text"
                    name="b_5831c9c1facd435d9e0595cfe_134a6d3153"
                    tabIndex="-1"
                    value=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex" style={{ alignItems: 'center' }}>
          <a className="x-logo" style={{ paddingTop: '0px' }} href="https://twitter.com/MasculineReset" target="_blank">
            <img src="./img/social/TwitterLogo.png" width="60px" style={{ marginTop: '0px' }} alt="Twitter Logo" />
          </a>
          <div className="sep_line" style={{ margin: '0px 8px 0px' }}></div>
          <a className="discord-logo" href="https://discord.gg/FJsjych3RN" target="_blank">
            <img src="./img/social/discord-logo-black.png" width="100px" style={{ margin: '0px 16px 0px' }} alt="Discord Logo" />
          </a>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Footer;
