import React, { useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { enc, AES } from 'crypto-js';

require('dotenv').config({ path: '../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');

// Function to decrypt data

function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}

const StripeComponent = () => {// eslint-disable-next-line
  const [isLoggedin, setIsLoggedin] = useState(!!localStorage.getItem('loggedInEmail'));// eslint-disable-next-line
  const [product, setProduct] = useState({
    name: 'TMReset Premium',
    price: 20,
    description: 'Early Access - 60% discount',
  });// eslint-disable-next-line
  const [email, setEmail] = useState('');

  const handleToken = (token) => {
    console.log(token);
    alert('Payment Success!');

    // Send payment token and email to server
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/premium1`, { token, email, decryptedEmail })
    .then(response => {
      window.location.reload();
    })
      .catch(error => console.log(error));
  };

  if (!isLoggedin) {
    return (
      <button className='btn button' onClick={() => window.location.href = '/api/signup'}>
        Sign Up
      </button>
    );
  }

  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');
  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;

  console.log(decryptedEmail);

  return (
    <StripeCheckout
      stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
      token={handleToken}
      name={product.name}
      description={product.description}
      amount={product.price * 100}
      currency="USD"
      zipCode={false}
      coupon="promo_1MguhrHbCahu14R1En1IWlhf"

    >
      <button className='btn button' style={{margin: "8px 0px"}}>Get Premium 60% off</button>
    </StripeCheckout>
  );
};

export default StripeComponent;
