import React, { Component } from 'react';
import Payment from './Stripe/Payment';
import Footer from './Footer';
class Premium extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0); // Scroll to the top of the page

    this.state = {
      firstName: 'John',
      lastName: 'Doe',
      email: 'johndoe@example.com',
      phoneNumber: '123-456-7890',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    };
  }

  render() {
    return (
      <div>
      <div className="container_payment_stripe Checkout" >
        <Payment />
      </div>
      <Footer />
      </div>
    );
  }
}

export default Premium;
