import React from 'react';
import YoutubeEmbed from '../components/YoutubeEmbed';
import image from '../img/ourmission/1000000850.png';
import StartJourney from '../components/StartJourney';
import Footer from '../components/Footer';

function OurMission() {


    return(
        <div>
        <h1 className="ourmission_text">Our Mission</h1>
        <div className="ourmission_bi OM_image" style={{backgroundImage: 'url(../img/ourmission_shorter.jpg)'}}></div>
        <div className='All-Wrap_mission'>
            <div className='OMcontainer' style={{marginBottom: '64px'}}>
            <div className='OMcontainer2' >
                <div className='MR56' >
                <h3 style={{display: 'flex', width: '100%', margin: '24px 0px 8px', maxWidth: '1200px'}}>The need for The Masculine Reset</h3>
                <p style={{display: 'flex', width: '100%', justifyContent: 'center', margin: '16px 0px 24px', maxWidth: '800px'}}>This platform empowers men to unlock their full potential and become the maximized version of themselves. It offers an educational and supportive environment for men to learn the skills and knowledge necessary to become competent, successful individuals in today's society. The platform provides practical advice, inspiring content, and a community for men to overcome challenges and unleash their full potential.</p>
                </div>
                
                <img className="TMR_img"src= {image} alt="staring into the sunset" width="30%" height="auto"></img>
            </div>
            <div className='OMcontainer3'>
            <h4 className='mobilePadding OurGoals' >Our goals</h4>
            <div className='mobileFlexC container row'>


                <div className='mission-card'>
                <h5 style={{display: 'flex', width: '100%', margin: '20px 0px 20px', maxWidth: '1200px'}}><span className='mission-card-number'>1.</span> Rebuild Men</h5>
                <p style={{display: 'flex', width: '100%', justifyContent: 'center', margin: '16px 0px 16px', maxWidth: '800px'}}>We help men learn to live their best lives through personal growth and development of necessary skills.</p>
                </div>
                <div className='mission-card'>
                <h5 style={{display: 'flex', width: '100%', margin: '20px 0px 20px', maxWidth: '1200px'}}><span className='mission-card-number'>2.</span> Restore Homes</h5>
                <p style={{display: 'flex', width: '100%', justifyContent: 'center', margin: '16px 0px 16px', maxWidth: '800px'}}>Empowering men will lead to the repair and restoration of homes, uniting families and strengthening each generation.</p>
                </div>
                <div className='mission-card'>
                <h5 style={{display: 'flex', width: '100%', margin: '20px 0px 20px', maxWidth: '1200px'}}><span className='mission-card-number'>3.</span> Renew Society</h5>
                <p style={{display: 'flex', width: '100%', justifyContent: 'center', margin: '16px 0px 16px', maxWidth: '800px'}}>Improving men and their homes will positively impact society as a whole and bring health and vitality to humanity.</p>
                </div>

             </div>
             </div>



                <div className='center OMvideo' ><YoutubeEmbed id="home_video" embedId="xiAqO5sJZpA"/></div>
                <div style={{display: 'flex', width: '100%', justifyContent:'center', margin: '0px 0px'}}>
                    
                  {/*  <p style={{maxWidth: '650px', fontSize: '18px', margin: '56px 48px'}}>The intent of this website is to help individuals who lack a masculine teacher in their life to learn the art of being a man. The website is designed to provide practical and informative guidance on various aspects of masculinity, including physical, mental, and emotional health. The website will offer a wide range of content including blogs, tutorials, and categories of videos, all aimed at helping individuals become the best version of themselves. Whether it be through learning about proper nutrition and exercise, improving mental toughness, or developing better communication skills, this website will provide the tools and resources needed to help individuals succeed in life. By providing a comprehensive and user-friendly platform, this website has the potential to make a real difference in the lives of those it serves.</p>
                    <p style={{maxWidth: '650px', fontSize: '18px', margin: '56px 48px'}}>This platform is dedicated to the development and cultivation of masculinity in individuals who may lack a strong male influence in their lives. With a focus on physical, mental, and emotional well-being, the website offers practical guidance and expert advice to help men achieve their full potential. Through a variety of educational content including blogs, tutorials, and video categories, men will gain a comprehensive understanding of what it means to be a masculine individual. From learning about proper nutrition and exercise to developing essential life skills like communication and mental toughness, this website provides a one-stop-shop for men seeking self-improvement and self-actualization. With its user-friendly interface and commitment to excellence, this platform is poised to make a lasting impact on the lives of those who use it.</p>
    */}</div>
                
            </div>
            <StartJourney />

      </div>
      <Footer />
      </div>
      
    )
    
}

export default OurMission