import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { enc, AES } from 'crypto-js';

const MenuLessons = ({ categories, title, description, id }) => {
    const prepositions = ['in', 'on', 'at', 'to', 'of', 'with', 'by', 'for', 'from', 'up', 'down', 'off', 'over', 'out', 'about', 'the', 'a', 'from', 'and'];

    const [completedLessons, setCompletedLessons] = useState([]);// eslint-disable-next-line
    const [isPremium, setIsPremium] = useState(false);

    require('dotenv').config({ path: '../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');


  
  // Function to decrypt data
// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}





  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');
  
  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;
  useEffect(() => {
    const fetchCompletedLessons = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/getCompletedLessons`, { params: { email: decryptedEmail } });
        setCompletedLessons(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (decryptedEmail) {
      fetchCompletedLessons();
    }// eslint-disable-next-line
  }, [decryptedEmail]);


  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/isUserPremium`, { params: { email: decryptedEmail } });
      setIsPremium(response.data.isPremium);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (decryptedEmail) {
      fetchUserData();
    }// eslint-disable-next-line
  }, [decryptedEmail]);

  const categoriesWithStatus = categories.map(category => {
    const lessonId = category.toLowerCase().replace(/\s/g, '-');
    const isCompleted = completedLessons.includes(lessonId);
    return {
      category,
      completed: isCompleted
    };
  });

  
  return (
    <div id={`menu_lesson${id}`}className='menu_lessons'>  {/* the rest of the code */}

        <div>
          <div className='menu-lesson-container' >            
          {id !== '0' && <span style={{fontSize: '20px', padding: '0px 12px 0px 0px', margin: '0px'}} className='lesson-description'>{id}.</span>}

            <h4 className='lesson-title' style={{padding: '0px 0px 0px 0px', color: 'black'}}>{title}</h4><span className='lesson-description'>{description}</span></div></div>
            <div className='ml-line-padding'><div className='line'></div></div>
            <ul className='menu_ul'>
            {categoriesWithStatus.map((category, index) => (
              <Link
              key={category.category}
              to={
                title.toLowerCase() === "introduction" ||
                title.toLowerCase() === "awaken" ||
                (title.toLowerCase() === "aim" && isPremium) ||
                (title.toLowerCase() === "build" && isPremium) ||
                (title.toLowerCase() === "empower"  && isPremium)
                

                  ? `/thepath/${title.toLowerCase().replace(/\s/g, "-")}/${category.category.toLowerCase().replace(/\s/g, "-")}`
                  : isPremium && (
                    title.toLowerCase() === "master" ||
                    title.toLowerCase() === "actualize")
                  ? `/thepath`
                  : `/getpremium`
              }
            >
              <li
                className={`nav-item nav-item2 lesson-item ${
                  title.toLowerCase() !== "introduction" &&
                  title.toLowerCase() !== "awaken" &&
                  title.toLowerCase() !== "aim" &&
                  title.toLowerCase() !== "build" &&
                  title.toLowerCase() !== "empower" 


                    ? "whitetext"
                    : ""
                }`}
              >
                <div className='lesson-name line-clamp2'>
                {category.category
                  .split(" ")
                  .map(
                    (word, index) =>
                      prepositions.includes(word.toLowerCase()) && index !== 0
                        ? word.charAt(0).toLowerCase() + word.slice(1)
                        : word.charAt(0).toUpperCase() + word.slice(1)
                  )
                  .join(" ")}
                  </div>
                <div
                  className={`lesson_status ${
                    category.completed ? "completed_lesson" : "uncompleted_lesson"
                  }`}
                ></div>
              </li>
            </Link>
            
))}
      </ul>
    </div>
  );
};

export default MenuLessons;
