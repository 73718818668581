import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { enc, AES } from 'crypto-js';
require('dotenv').config({ path: '../../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
console.log(encryptionKey.length);

// An encrypt function
function encryptData(text) {
  const encrypted = AES.encrypt(text, encryptionKey).toString();
  return encrypted;
}

// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}

class LoginForm extends Component {
    constructor(props) {
      super(props);
  
      // Set the initial state
      this.state = {
        email: '',
        password: '',
        loginSuccess: null, // Add a new state property to track login success
        loginError: null, // Add a new state property to track login error
        isLoading: false // Add a new state property to track loading state

      };
      
    }

   
  
    // Handle form submission
    handleSubmit(event) {
      event.preventDefault();
      const { email, password } = this.state;
      this.setState({ loginError: null, isLoading: true });
      console.log(process.env.REACT_APP_SERVER_URL)
console.log(`${process.env.REACT_APP_SERVER_URL}/api/login`);

      // Make an AJAX request to authenticate the user
      axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login`, { email, password })
      .then((response) => {
        if (response.data.success) {
            console.log('Login successful');
            let prevUrl = localStorage.getItem('prevUrl')
            console.log(email)
            const encryptedEmail = encryptData(email);
            const encryptedUsername = encryptData(response.data.username);


            localStorage.setItem('loggedInUser', encryptedUsername); // Add username to local storage
            localStorage.setItem('loggedInEmail', encryptedEmail); // Add email to local storage
            const storedEmail = localStorage.getItem('loggedInEmail');
            const storedUsername = localStorage.getItem('loggedInUser');
            const decryptedEmail = decryptData(storedEmail);
            const decryptedUsername = decryptData(storedUsername);

console.log(decryptedEmail); // Outputs: test@example.com
console.log(decryptedUsername); // Outputs: testuser
            this.setState({ loginSuccess: true, loginError: false }); // Update loginSuccess state property
            setTimeout(() => {
                if (prevUrl) {
                    console.log(prevUrl);
                    window.location.href = prevUrl;
                } else {
                    this.props.history.push('/'); // Redirect to default URL if prevUrl is not set or is invalid
                  }
              }, 1000);
        }  else {
          console.log('Login failed');
          this.setState({ loginSuccess: false, loginError: response.data.message }); // Update loginError state property
        }
      })
      .catch((error) => {
        console.error(error);
        console.log('Login failed'); // Log the error message here
        this.setState({ loginSuccess: false, loginError: 'An error occurred. Please try again.' }); // Update loginError state property with a generic error message
      })
      .finally(() => {
        // Set isLoading back to false after the request is complete
        this.setState({ isLoading: false });
      });
    }


  
  
    // Handle changes to the email input
    handleEmailChange(event) {
      this.setState({ email: event.target.value });
    }
  
    // Handle changes to the password input
    handlePasswordChange(event) {
      this.setState({ password: event.target.value });
    }
  
    render() {
      // Render a message based on the login success state
      let message;
      const submitButtonText = this.state.isLoading ? 'Loading...' : 'Submit';

      if (this.state.loginSuccess === true) {
        message = <div className="alert alert-success">Login successful!</div>;
      } else if (this.state.loginError !== null) {
        message = <div className="alert alert-danger">Login failed. Please check your email and password and try again.</div>;
        console.log('Login failed'); // Add this line to log the error message
      }
      
      
      
 

  
      return (
        <div className='All-Wrap' style={{height: '100vh'}}>
        <div className='signupLogin-wrap'>
          <form className='form-wrap' onSubmit={event => this.handleSubmit(event)}>
            <h1 style={{fontSize: '32px', margin: '0px'}}>Log In</h1>
            <div className='loginGroup' >
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} onChange={event => this.handleEmailChange(event)} required/>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" className="form-control" id="password" placeholder="Password" value={this.state.password} onChange={event => this.handlePasswordChange(event)} required/>
            </div>
            <button type="submit" className="btn btn-primary submit-button" disabled={this.state.isLoading}>{submitButtonText}</button>
            {message}

            </div>
            <Link className="signupLink" to='/api/signup'>Or sign up for a new account</Link>

          </form>

        </div>
        </div>
      );
    }
  }

  export default LoginForm;