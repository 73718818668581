import React from 'react';
import image from '../img/logo/TMR_logo.png'
import YoutubeEmbed from '../components/YoutubeEmbed';

function Mastery() {
    return(
        <div className='All-Wrap'>
        <p>This is my mastery.</p>
        <div className='All-Wrap'>
        <h1>Youtube Embed</h1>
        <YoutubeEmbed embedId="gLjvSVQ0EVA" />
        <p>Coming soon! A platform for men to reclaim their power, purpose and vitality.</p>
        <img className="TMR_img"src= {image} alt="staring into the sunset" width="30%" height="auto"></img>
      </div>
      <div className='All-Wrap'>
        <p>Coming soon! A platform for men to reclaim their power, purpose and vitality.</p>
        <img className="TMR_img"src= {image} alt="staring into the sunset" width="30%" height="auto"></img>
      </div>

      </div>
    )
}

export default Mastery