import React, { Component } from 'react';
import StripeProfile from './Stripe/StripeProfile';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { enc, AES } from 'crypto-js';
import Footer from './Footer';
const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');

// Function to decrypt data
// An encrypt function
function encryptData(text) {
  const encrypted = AES.encrypt(text, encryptionKey).toString();
  return encrypted;
}

// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}

let loggedInUser = localStorage.getItem('loggedInUser');
let loggedInEmail = localStorage.getItem('loggedInEmail');

const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

loggedInUser = decryptedUsername;
loggedInEmail = decryptedEmail;

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: `${decryptedUsername}`,
      email: `${decryptedEmail}`,
      bio: '',
      isPremium: false // default value for isPremium
    };
  }

  async componentDidMount() {
    const loggedInEmail = localStorage.getItem('loggedInEmail');
    const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  
    if (decryptedEmail) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/isUserPremium`, { params: { email: decryptedEmail } });
        this.setState({ isPremium: response.data.isPremium }, () => {
          console.log(this.state.isPremium);
        });
      } catch (error) {
        console.error(error);
      }
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/userbio`, { params: { email: decryptedEmail } });
      const user = response.data.user;
      this.setState({ bio: user.bio });
    } catch (error) {
      console.error(error);
    }
  }
  

  handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    this.setState({ username: newUsername });
  }

  handleUsernameBlur = async () => {
    try {
      const loggedInUser = localStorage.getItem('loggedInUser');
      const loggedInEmail = localStorage.getItem('loggedInEmail');

      const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
      const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

      if (this.state.username.trim() === '') {
        alert('Why are you running? Username cannot be left blank');
        return;
      }

      if (this.state.username.length > 15) {
        alert(`Username must be 15 characters or less. Current count: ${this.state.username.length} `);
        this.setState({ username: this.state.username.substring(0, 15) });
        return;
      }
  

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/updateUsername`, {
        email: decryptedEmail,
        username: this.state.username,
        loggedInUser,
      });
      console.log(response.data);
      if (response.data === 'Username updated successfully') {
        localStorage.setItem('loggedInUser', encryptData(this.state.username));
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleUsernameKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }

  handleBioChange = (event) => {
    const newBio = event.target.value;
    if (newBio.length <= 500) {
      this.setState({ bio: newBio });
    } else {
      alert('Bio must be 500 characters or less.');
    }
  }
  
  handleBioBlur = async () => {
    try {
      const loggedInEmail = localStorage.getItem('loggedInEmail');
      const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  
      if (this.state.bio.length > 300) {
        alert(`Bio must be 150 characters or less.  Current count: ${this.state.bio.length}`);
        return;
      }


      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/updateBio`, {
        email: decryptedEmail,
        bio: this.state.bio,
      });
      
     

      console.log(response.data);
      if (response.data === 'Bio updated successfully.') {
      }
    } catch (error) {
      console.error(error);
    }
  }


  render() {
    return (
      <div className='profile-container'>
      <div className="container screenwidth" style={{paddingTop: 'var(--header-padding)'}}>
        <div className="profileRow">
        <h3>Profile</h3>

        <div className="col-sm-8">
        <label style={{fontSize: "14px", paddingBottom: '6px'}} htmlFor="username">Username:</label>
        <input className='profile_input' type="text" id="username" value={this.state.username} onChange={this.handleUsernameChange} onBlur={this.handleUsernameBlur} onKeyUp={this.handleUsernameKeyUp}/>
            <label style={{fontSize: "14px" , paddingBottom: '6px'}} htmlFor="email">Email:</label>
            <input className='profile_input' type="email" id="email" value={this.state.email} disabled />
            <label style={{fontSize: "14px" , paddingBottom: '6px'}} htmlFor="bio">My Plan:</label>
        <textarea className='profile_input' id="bio" value={this.state.bio} onChange={this.handleBioChange} onBlur={this.handleBioBlur} placeholder={'Who are you now, who will you become, how are you getting there?'}/>
        <span className='help_text '>Having a clear purpose is essential to developing as a man. Even if you don't know yours yet, be as specific and succinct as you can now and refine your bio later.</span>
          </div>
          
          
          {this.state.isPremium ? null : <Link to="/getpremium"><button className='btn button screenwidth noMargin'>Get Premium 60% off </button></Link>}
          
{/* <StripeProfile /> */}
          
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}

export default ProfilePage;
