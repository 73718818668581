import React from 'react';
import Footer from '../components/Footer';
const NotFoundPage = () => {
    return (
        <div className='profile-container'>
            <div className="container screenwidth" style={{ paddingTop: 'var(--header-padding)' }}>
                <div className="profileRow">
                    <div>
                        <h1>Oops! Lost in the Wilderness</h1>
                        <p>Looks like you've ventured into uncharted territory.</p>
                        <p style={{ display: 'flex', flexDirection: 'row' }}>
                            Get back to the <strong><a href="/" style={{ color: 'var(--lighter_primary_color)' }}>&nbsp;home page</a></strong>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NotFoundPage;
