import React from 'react';
import image from '../img/logo/TMR_logo.png'
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import { enc, AES } from 'crypto-js';

require('dotenv').config({ path: '../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;


  

// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}

function Header() {

  
  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');

  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;


  function handleLogout() {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('loggedInEmail');

     if (window.location.pathname === '/profile') {
    window.location.href = '/';
     }
     else{
    window.location.reload();}
  }
  

  function handleLogin() {
    const currentUrl = window.location.pathname;
    if (currentUrl !== '/api/signup' && currentUrl !== '/api/login') {
      localStorage.setItem('prevUrl', currentUrl); // Store the current URL as prevUrl
    }
    // ...
  }
  
  
  return (
    <div>
      <nav className="navbar">
        <div className='nav-max'>
        <Link to="/"><div className="logo"><img src= {image} alt="logo" width="100px" height="auto"></img>
</div></Link>
        <ul className='nav-links'>
        <div className='hidenav'>
        <div className='nav-container'>
        {!loggedInUser && (

        <li className="nav-item nav_border"> 
        <Link to="/">Home</Link>
         </li>
        )}
        <li  className='port nav-item nav_border'> 
                <div className='outer'>
                    <Link to="/thepath" className="drop_text">The Path< Dropdown /></Link> 
                        <ul className="dropdown-content">
                        <li><Link to= '/thepath' onClick={() => window.location.replace("/thepath")} className='dropdown-item'><div className='dd_f1'> </div>&nbsp;Introduction<div className='dd_f2'> &nbsp;- Becoming the man you want to be through TMReset</div></Link></li>
                            <li><Link to="/thepath#menu_lesson1" onClick={() => window.location.replace("/thepath#menu_lesson1")} className='dropdown-item' ><div className='dd_f1'>1. </div>&nbsp;Awaken<div className='dd_f2'> &nbsp;- Recognition of inadequacy, desire for change.</div></Link></li>
                            <li><Link to="/thepath#menu_lesson2" onClick={() => window.location.replace("/thepath#menu_lesson2")} className='dropdown-item' ><div className='dd_f1'>2. </div>&nbsp;Aim<div className='dd_f2'> &nbsp;- Determine the man you want to be and how you'll become him.</div></Link></li>
                            <li><Link to="/thepath#menu_lesson3" onClick={() => window.location.replace("/thepath#menu_lesson3")} className='dropdown-item' href="sponsors.html"><div className='dd_f1'>3. </div>&nbsp;Build<div className='dd_f2'> &nbsp;- Establish optimal habits and mindset to achieve success</div></Link></li>
                            <li><Link to="/thepath#menu_lesson4" onClick={() => window.location.replace("/thepath#menu_lesson4")} className='dropdown-item' href="sponsors.html"><div className='dd_f1'>4. </div>&nbsp;Empower<div className='dd_f2'> &nbsp;- Develope self-empowerment through intentional actions.</div></Link></li>
                            <li><Link to="/thepath#menu_lesson5" onClick={() => window.location.replace("/thepath#menu_lesson5")} className='dropdown-item' href="sponsors.html"><div className='dd_f1'>5. </div>&nbsp;Reflect<div className='dd_f2'> &nbsp;- Evaluate progress, making necessary adjustments.</div></Link></li>
                            <li><Link to="/thepath#menu_lesson6" onClick={() => window.location.replace("/thepath#menu_lesson6")} className='dropdown-item' href="sponsors.html"><div className='dd_f1'>6. </div>&nbsp;Master<div className='dd_f2'> &nbsp;- Control over thoughts, emotions, and actions.</div></Link></li>
                            <li><Link to="/thepath#menu_lesson7" onClick={() => window.location.replace("/thepath#menu_lesson7")} className='dropdown-item' href="sponsors.html"><div className='dd_f1'>7. </div>&nbsp;Actualize<div className='dd_f2'> &nbsp;- Live a meaningful, purposeful life.</div></Link></li>

                        </ul>
                </div>
        </li>
        <li className='port nav-item nav_border' style={{display: 'none'}}> 
                <div className='outer'>
                    <a className="drop_text no_pointer" href='/mindset'>Areas of Mastery< Dropdown /></a> 
                        <ul className="dropdown-content">
                            <li><Link to="/mindset" className='dropdown-item nav-item' href="vitality_fitness.html">Mindset</Link></li>
                            <li><Link to="/about" className='dropdown-item nav-item' href="vitality_fitness.html">Physical Development & Health</Link></li>
                            <li><Link to="/about" className='dropdown-item nav-item' href="sponsors.html">Emotional Intelligence & Mental Health</Link></li>
                            <li><Link to="/about" className='dropdown-item nav-item' href="sponsors.html">Social Skills & Relationships</Link></li>
                        </ul>
                </div>
        </li>
        

       
         <li className="nav-item nav_border"> 
        <Link to="/knowledge-center">Knowledge Center</Link>
         </li>
         <li className="nav-item nav_border"> 
        <Link to="/ourmission">Our Mission</Link>
         </li>
         {!loggedInUser && (
            <li className="nav-item right nav_border">
                <Link to="/api/login" onClick={handleLogin}>
                  Log In
                </Link>
            </li>
          )}
          {!loggedInUser && (
            <li className="nav-item right no_border">
                <Link to="/api/signup" onClick={handleLogin} className=' no-padding'><button className="button white-button header-button no-margin">
                  Sign Up
                </button></Link>
            </li>
          )}
          {loggedInUser && (
            <li className="nav-item right nav_border">
              <Link to="/profile">{loggedInUser}'s Profile</Link>
            </li>
          )}  
          {loggedInUser && (
        <li className="nav-item right no_border">
          <Link onClick={handleLogout}>Logout</Link>
        </li>
         )}
           
        </div>
        </div>

        <li className='mobileOnly' style={{padding: "4px"}}><HamburgerMenu /></li>
          
        </ul>
        </div>
      </nav>

  
    </div>
  );
}

export default Header;