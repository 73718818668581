import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';



const NextLessonButton = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [category, setCategory] = useState('');
    const [lesson, setLesson] = useState('');
  
    
    useEffect(() => {
      const path = location.pathname;
      console.log('Current Path:', path);

      const parts = path.split('/');

      if (parts.length >= 3) {
          let categoryName = parts[2];

          if (categoryName in categories) {
              setCategory(categoryName);
              const lessonName = parts[3]
                  .toLowerCase()
                  .split('-')
                  .map((word, index) => {
                      const isPreposition = ['through', 'in', 'how', 'on', 'at', 'to', 'of', 'with', 'by', 'for', 'from', 'up', 'down', 'off', 'over', 'out', 'about', 'the', 'a', 'from', 'and', 'an'].includes(word) && index !== 0;
                      return isPreposition ? word : word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(' ');

              if (categories[categoryName].includes(lessonName)) {
                  setLesson(lessonName);
                  console.log('Current Lesson:', lessonName);

                  // Log index positions
                  console.log('Current Category Index:', categoriesList.indexOf(categoryName));
                  console.log('Current Lesson Index:', categories[categoryName].indexOf(lessonName));
              } else {
                  setLesson(categories[categoryName][0]);
                  console.log('This is not a valid lesson name; setting to the first lesson.');
                  console.log('Invalid Lesson Name:', lessonName); // Add this line for additional logging
              }
          } else {
              setCategory('intro');
              setLesson('purpose-of-the-masculine-reset');
              console.log('Invalid category; setting to intro and purpose-of-the-masculine-reset.');
          }
      } else {
          setCategory('intro');
          setLesson('purpose-of-the-masculine-reset');
          console.log('Invalid path; setting to intro and purpose-of-the-masculine-reset.');
      }
  }, [location]);

      


  const categories = {
    introduction: ['Getting the Most out of This Course', 'Joining a Community of Men Who Share Your Goals and Values'],
       awaken: ['The Crisis of Masculinity: Exploring the Challenges Facing Men Today', 'The Necessity of Reclaiming Masculinity', 'Recognizing how Men Are Taught to Drift through Life', 'An Honest Look at Yourself', 'Setting the Foundation for Growth'],
      aim: ['Overcoming Procrastination: Building Momentum and Productivity', 'Identifying Your Why: Finding Your Motivation', 'Creating a Personal Mission Statement: Aligning Your Goals with Your Purpose', 'The Power of Dopamine: Feeling Motivated', 'Developing a Positive Mental Attitude', 'Understanding the Different Types of Masculine Men', 'Defining Your Masculine Identity',  'Creating Your Vision of Masculine Excellence',    'Achieving Success through Persistence'],
      build: [
        'Establishing Healthy Habits',
        'Improving Physical Health and Wellness',
        'Developing Mental Toughness',
        'Building Emotional Intelligence',
        'Improving Communication Skills',
        'Cultivating Relationships and Connections',
        'Mastering Time Management',
        'Effective Communication Skills',
        'Enhancing Decision Making Skills',
        'Stress Management and Resilience',
        'Goal Setting and Achievement',
        'Conflict Resolution and Effective Communication',
        'Building Confidence and Self Esteem',
        'Leadership Skills Development',
        'Building Resilient Relationships'
      ],
      empower: ['Fostering a Growth Mindset', 'Identifying and Challenging Limiting Beliefs', 'Practicing Self Care and Self Love','Mindfulness and Meditation', 'Personal Branding and Image', 'Developing a Growth Oriented Attitude','Building a Support System for Growth and Empowerment', 'Empowering Others and Creating a Positive Impact'],
      reflect: ['Assessing Personal Growth and Progress','Evaluating Current Habits and Goals','Adjusting and Refining Habits', 'Refining Goals and Objectives', 'Celebrating Successes and Learning From Failures', 'Preparing for The Next Phase Of Growth'],
      master: ['Achieving Mastery Of Self Discipline', 'Maintaining Control Over Thoughts, Emotions, and Actions', 'Developing A Strong Sense Of Personal Identity', 'Improving Leadership Skills', 'Living With Purpose and Meaning', 'Inspiring and Guiding Others'],
      actualize:['Living A Fulfilling Life','Pursuing Personal Passions and Interests', 'Achieving Personal and Professional Success', 'Making A Positive Impact On Society', 'Continuing To Grow and Evolve','Becoming A Role Model and Mentor for Others'],
  };

const categoriesList = Object.keys(categories);

const getNextLesson = () => {
  const categoryIndex = categoriesList.indexOf(category);
  const lessonIndex = categories[category].indexOf(lesson);

  if (lessonIndex < categories[category].length - 1) {
      const nextLessonIndex = lessonIndex + 1;
      setLesson(categories[category][nextLessonIndex]);
      navigate(`/thepath/${category.toLowerCase()}/${categories[category][nextLessonIndex].toLowerCase().split(' ').join('-')}`);
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
      });
      window.dispatchEvent(new Event('scroll'));
  } else if (categoryIndex < categoriesList.length - 1) {
      const nextCategory = categoriesList[categoryIndex + 1].toLowerCase();
      setCategory(nextCategory);
      setLesson(categories[nextCategory][0]);
      navigate(`/thepath/${nextCategory}/${categories[nextCategory][0].toLowerCase().split(' ').join('-')}`);
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
      });
      window.dispatchEvent(new Event('scroll'));
  } else {
      console.log('No more lessons!');
  }
};


  
  
  

return (
  <>
    <button onClick={getNextLesson} className='btn button lesson_button nextLesson_button'>Next Lesson</button>
    <p style={{display: 'none'}}>Current category: {category}</p>
    <p style={{display: 'none'}}>Current lesson: {lesson}</p>
  </>
);
};

export default NextLessonButton;