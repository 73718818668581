import React from 'react';
import { Link } from 'react-router-dom';

const BlogItem = ({ id, title, image, opening }) => {
  const hasImage = !!image; // Check if image is provided

  return (
    <Link to={`/knowledge-center/${id}`} className="blog-link">
      <div className={`blog-item ${hasImage ? 'with-image' : ''}`}>
        {hasImage && (
          <div className="blog-image-container">
            <img className='blog-image' src={image} alt={title} />
          </div>
        )}
        <div className="blog-content">
          <h4 className='line-clamp2'>{title}</h4>
          <p className='line-clamp3'>{opening}</p>
        </div>
      </div>
    </Link>
  );
};

export default BlogItem;
