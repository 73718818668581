import React from 'react';


const Button = ({ label, className, handleClick }) => (
  <button
    className={`button ${className}`}
    onClick={handleClick}
  >
    {label}
  </button>
);

export default Button;