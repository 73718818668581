import React, { useState, useEffect } from 'react';
import StripeComponent from './StripeComponent';
import axios from 'axios';

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');

require('dotenv').config({ path: '../../../.env' });

const StripeProfile = () => {
  const [isPremium, setIsPremium] = useState(false);
  
  // Function to decrypt data
  function decryptData(data) {
    const decipher = crypto.createDecipher('aes-256-cbc', encryptionKey);
    let decrypted = decipher.update(data, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  }

  let loggedInUser = localStorage.getItem('loggedInUser');
  let loggedInEmail = localStorage.getItem('loggedInEmail');
  
  const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
  const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

  loggedInUser = decryptedUsername;
  loggedInEmail = decryptedEmail;
  console.log(decryptedEmail)

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/isUserPremium`, { params: { email: decryptedEmail } });
      setIsPremium(response.data.isPremium);
      console.log(response)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (decryptedEmail) {
      fetchUserData();
    }// eslint-disable-next-line
  }, [decryptedEmail]);

  return (
    <div>
      {isPremium ? (
        <h5>Thanks for being a premium member!</h5>
      ) : (
        <div className='stripeProfileCard' >
          <div>
        {loggedInEmail ? (<h5>Commit today to reclaiming your masculinity</h5>) : (<h5>Sign up to start reclaiming your masculinity</h5>)}
        {loggedInEmail ? (<p style={{maxWidth: "450px"}}>Complete access to all present and soon to come TMReset premium content. </p>): 
        (<p style={{maxWidth: "450px", display: "none"}}></p>)}
          </div>
          <StripeComponent />
        </div>
      )}
    </div>
  );
};

export default StripeProfile;
