import React from 'react';
import doubleDown from '../img/icons/double down.svg';

function ScrollDown() {
  const handleClick = (event) => {
    event.preventDefault();
    const nextSection = document.getElementById("scroll_down");
    nextSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <a href="#scroll_down" style={{display: 'inline'}}>
      <img
        id="scroll_down"
        className="icon"
        src={doubleDown}
        alt="scroll down"
        onClick={handleClick}
      />
    </a>
  );
}

export default ScrollDown;
