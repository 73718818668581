import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TwitterTimeline = () => {
  return (
    <div>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="MasculineReset" // Replace with your Twitter handle
        options={{
          height: 400, // Adjust the height as needed
          theme: 'light', // 'light' or 'dark' theme
        }}
      />
    </div>
  );
};

export default TwitterTimeline;
