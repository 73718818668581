import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const StickyNav = () => {
    const location = useLocation();
    const [primarySection, setPrimarySection] = useState(null);
    let stickynavLinks = [];

    useEffect(() => {
        const sectionEl = document.querySelector(stickynavLinks[0].to);
        setPrimarySection(sectionEl);// eslint-disable-next-line
      }, []);
      
      

      useEffect(() => {
        const handleScroll = () => {
          const sectionEls = stickynavLinks.map(link => document.querySelector(link.to));
          const distances = sectionEls.map(el => {
            if (el) {
              return Math.abs(el.getBoundingClientRect().top);
            } else {
              return 0;
            }
          });
                    const closestIndex = distances.indexOf(Math.min(...distances));
          const closestSection = sectionEls[closestIndex];
          setPrimarySection(closestSection);
          
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };// eslint-disable-next-line
      }, [location.pathname]);
      
      const handleNavClick = (event) => {
        event.preventDefault(); // prevent default behavior of the anchor tag

        const clickedSection = document.querySelector(event.target.getAttribute('href'));
        clickedSection.scrollIntoView({ behavior: "smooth" }); // scroll to the target section
        setPrimarySection(clickedSection);

        
      }
    
      //introduction: ['The Masculine Reset: What It Is and Why It Matters', 'The Crisis of Masculinity: Exploring the Challenges Facing Men Today', 'The Benefits of Reclaiming Masculinity: How It Can Improve Your Life and Relationships', 'Getting the Most out of This Course: How to Approach and Use the Lessons Effectively', 'Setting Personal Expectations and Goals: Defining What You Want to Achieve through the Masculine Reset', 'Pre-Assessment of Your Current Masculine Traits: Understanding Your Strengths and Weaknesses', 'Creating a Supportive Environment: Building a Community of Men Who Share Your Goals and Values']    awaken: ['Recognizing the Need for Change', 'Understanding the Societal and Cultural Impact on Masculinity', 'Examining Current Beliefs and Attitudes', 'Understanding the Impact of Upbringing and Family Background', 'Evaluating Current Level of Self Discipline', 'Setting the Foundation for Growth'],

   if (location.pathname === '/thepath/awaken/the-crisis-of-masculinity:-exploring-the-challenges-facing-men-today') {
    stickynavLinks = [
        { text: 'The attack on masculinity', to: '#x-0' },
        { text: 'Finding a place in society', to: '#x-1' },
        { text: 'Societal impact on masculinity', to: '#x-2' },
        { text: "Cultural impact on masculinity", to: '#x-3' },
        { text: "State's role in undermining masculinity", to: '#x-4' },
        { text: 'Resistence to these forces', to: '#x-5' },
        { text: 'Embracing true masculinity', to: '#x-6' },


    ];
  } else if (location.pathname === '/thepath/awaken/the-necessity-of-reclaiming-masculinity') {
    stickynavLinks = [
        { text: 'Better health and well-being', to: '#x-0' },
        { text: 'Increased success and achievement', to: '#x-1' },
        { text: 'Enhanced leadership abilities', to: '#x-2' },
        { text: 'Heightened individual happiness', to: '#x-3' },
        { text: 'Increased confidence', to: '#x-4' },
        { text: 'Improved relationships', to: '#x-5' },
        { text: 'Improved society', to: '#x-6' },

    ];
    }else if (location.pathname === '/thepath/introduction/getting-the-most-out-of-this-course') {
    stickynavLinks = [
        { text: 'It all starts here', to: '#x-0' },
        { text: 'How to approach the course', to: '#x-1' },
        { text: 'About the structure', to: '#x-2' },
        { text: "The journey's not easy, but it's worth it.", to: '#x-3' },
        { text: 'Tasks', to: '#x-4' },

    ];
  }    else if (location.pathname === '/thepath/introduction/joining-a-community-of-men-who-share-your-goals-and-values') {
        stickynavLinks = [
          { text: 'Importance of male friendship', to: '#x-0' },
          { text: 'Traditional model of male friendship', to: '#x-1' },
          { text: 'Detrimental effects of independence', to: '#x-2' },
          { text: 'Joining a community of men', to: '#x-3' },
          { text: "TMReset's discord community", to: '#x-4' },
          { text: 'Tasks', to: '#x-5' },
      ];
    } else if (location.pathname === '/thepath/introduction/setting-personal-expectations-and-goals') {
        stickynavLinks = [
            { text: 'Contact Link 0', to: '#x-0' },
            { text: 'Contact Link 1', to: '#x-1' },
            { text: 'Contact Link 2', to: '#x-2' },
            { text: 'Contact Link 3', to: '#x-3' },
            { text: 'Contact Link 4', to: '#x-4' },
            { text: 'Contact Link 5', to: '#x-5' },
            { text: 'Contact Link 6', to: '#x-6' },
            { text: 'Contact Link 7', to: '#x-7' },
            { text: 'Contact Link 8', to: '#x-8' },
        ];
      } else if (location.pathname === '/thepath/introduction/creating-a-supportive-environment') {
        stickynavLinks = [
            { text: 'Contact Link 0', to: '#x-0' },
            { text: 'Contact Link 1', to: '#x-1' },
            { text: 'Contact Link 2', to: '#x-2' },
            { text: 'Contact Link 3', to: '#x-3' },
            { text: 'Contact Link 4', to: '#x-4' },
            { text: 'Contact Link 5', to: '#x-5' },
            { text: 'Contact Link 6', to: '#x-6' },
            { text: 'Contact Link 7', to: '#x-7' },
            { text: 'Contact Link 8', to: '#x-8' },
        ];
      }   else if (location.pathname === '/thepath/awaken/an-honest-look-at-yourself') {
        stickynavLinks = [
            { text: 'Acknowledge your weaknesses', to: '#x-0' },
            { text: 'Embrace your strengths', to: '#x-1' },
            { text: 'Face your fears', to: '#x-2' },
            { text: 'Take responsibility for your life', to: '#x-3' },
            { text: 'Embrace your true self', to: '#x-4' },
            { text: 'Take an honest look at yourself', to: '#x-5' },
            { text: 'Tasks', to: '#x-6' },

        ];
      }   else if (location.pathname === '/thepath/awaken/setting-the-foundation-for-growth') {
        stickynavLinks = [
          { text: 'Moving forward', to: '#x-0' },
          { text: 'Use your struggle', to: '#x-1' },
          { text: "What's to come", to: '#x-2' },
      ];
  } else if (location.pathname === '/thepath/awaken/understanding-the-societal-and-cultural-impact-on-masculinity') {
    stickynavLinks = [
        { text: 'Societal impact on masculinity', to: '#x-0' },
        { text: 'Cultural impact on masculinity', to: '#x-1' },
        { text: "State's role in undermining masculinity", to: '#x-2' },
        { text: 'Resistence to these forces', to: '#x-3' },
        { text: 'Additional resources', to: '#x-4' },

    ];
  }else if (location.pathname === '/thepath/awaken/recognizing-how-men-are-taught-to-drift-through-life') {
    stickynavLinks = [
        { text: 'Upbringing and social conditioning', to: '#x-0' },
        { text: 'Lack of personal responsibility', to: '#x-1' },
        { text: 'Fear of failure', to: '#x-2' },
        { text: 'Use of media', to: '#x-3' },
  
    ];
  }else if (location.pathname === '/thepath/aim/identifying-your-why:-finding-your-motivation') {
    stickynavLinks = [
      { text: 'Finding your why', to: '#x-0' },
      { text: 'Reflect on your past', to: '#x-1' },
      { text: 'Identify your core values', to: '#x-2' },
      { text: 'Find your passion and purpose', to: '#x-3' },
      { text: "Can't find your why?", to: '#x-4' },
      { text: 'Tasks', to: '#x-5' },

  ];
} else if (location.pathname === '/thepath/aim/creating-a-personal-mission-statement:-aligning-your-goals-with-your-purpose') {
  stickynavLinks = [
    { text: 'Defining your purpose', to: '#x-0' },
    { text: 'Crafting your mission statement', to: '#x-1' },
    { text: 'Aligning your goals and mission statement', to: '#x-2' },
    { text: 'Living your mission statement', to: '#x-3' },
    { text: 'Tasks', to: '#x-4' },

];
} else if (location.pathname === '/thepath/aim/the-power-of-dopamine:-feeling-motivated') {
  stickynavLinks = [
    { text: 'Dopamine and the reward system', to: '#x-0' },
    { text: 'The power of meaning and fulfillment', to: '#x-1' },
    { text: 'Steps to finding meaning and fulfillment', to: '#x-2' },
    { text: 'Tasks', to: '#x-3' },


];
}  else if (location.pathname === '/thepath/aim/overcoming-procrastination:-building-momentum-and-productivity') {
  stickynavLinks = [
    { text: 'Understanding procrastination', to: '#x-0' },
    { text: 'Developing a productive mindset', to: '#x-1' },
    { text: 'The Pomodoro Technique', to: '#x-2' },
    { text: 'Eliminating distractions', to: '#x-3' },
    { text: 'Rewarding yourself for productivity', to: '#x-4' },
];
} else if (location.pathname === '/thepath/aim/understanding-the-different-types-of-masculine-men') {
  stickynavLinks = [
    { text: 'The Provider', to: '#x-0' },
    { text: 'The Warrior', to: '#x-1' },
    { text: 'The Sage', to: '#x-2' },
    { text: 'The Artist', to: '#x-3' },
    { text: 'The Adventurer', to: '#x-4' },
    { text: 'The Lover', to: '#x-5' },
    { text: 'Which blend are you?', to: '#x-6' },

];
} else if (location.pathname === '/thepath/aim/developing-a-positive-mental-attitude') {
  stickynavLinks = [
    { text: 'The power of positive thinking', to: '#x-0' },
    { text: 'The role of beliefs', to: '#x-1' },
    { text: 'Overcoming obstacles with positive beliefs', to: '#x-2' },
    { text: 'Tasks', to: '#x-3' },
];
} else if (location.pathname === '/thepath/aim/creating-your-vision-of-masculine-excellence') {
  stickynavLinks = [
    { text: 'Aiming Towards Virtues and Competency', to: '#x-0' },
    { text: 'Defining Virtues and Competency', to: '#x-1' },
    { text: 'The Power of Virtues', to: '#x-2' },
    { text: 'Cultivating Virtues', to: '#x-3' },
    { text: 'Striving for Competency', to: '#x-4' },
    { text: 'Famous Men of Virtue and Competency', to: '#x-5' },
    { text: 'Creating Your Vision of Masculine Excellence', to: '#x-6' },
    { text: 'Taking Action Towards Your Vision', to: '#x-7' },
    { text: 'Conclusion', to: '#x-8' },

  ];
}

else if (location.pathname === '/thepath/aim/achieving-success-through-persistence') {
  stickynavLinks = [
    { text: 'The power of habit', to: '#x-0' },
    { text: 'Understanding grit', to: '#x-1' },
    { text: 'Achieving Success through Persistence', to: '#x-2' },
    { text: 'Tasks', to: '#x-3' },
];

} else if (location.pathname === '/thepath/aim/defining-your-masculine-identity') {
  stickynavLinks = [
    { text: 'Introduction', to: '#x-0' },
    { text: 'Self-Reflection: Know Thyself', to: '#x-1' },
    { text: 'Set Clear Values and Principles', to: '#x-2' },
    { text: 'Aspirations and Goals', to: '#x-3' },
    { text: 'Surround Yourself with Positivity', to: '#x-4' },
    { text: 'Embrace Continuous Growth', to: '#x-5' },
    { text: 'Conclusion', to: '#x-6' },
  ];
}

else if (location.pathname === '/thepath/build/establishing-healthy-habits') {
  stickynavLinks = [
    { text: 'Unleashing Peak Performance', to: '#x-0' },
    { text: 'Fortifying Mental Resilience', to: '#x-1' },
    { text: 'Amplifying Cognitive Agility', to: '#x-2' },
    { text: 'Fostering Effective Time Management', to: '#x-3' },
    { text: 'Nurturing Emotional Intelligence', to: '#x-4' },
    { text: 'Effective Commmunication', to: '#x-5' },
    { text: 'Tasks', to: '#x-6' },
  ];
} else if (location.pathname === '/thepath/recognizing-the-desire-for-change') {
    stickynavLinks = [
      { text: 'Contact Link 21', to: '/Contact/Link1' },
      { text: 'Contact Link 2', to: '/Contact/Link2' },
      { text: 'Contact Link 3', to: '/Contact/Link3' },
    ];
  }

  else if (location.pathname === '/thepath/build/improving-physical-health-and-wellness') {
    stickynavLinks = [
      { text: "The Warrior's Physique", to: '#x-0' },
    { text: 'Leading by Example', to: '#x-1' },
    { text: 'Fueling the Machine', to: '#x-2' },
    { text: 'The Energy Reservoir', to: '#x-3' },
    { text: "Overcoming Life's Challenges", to: '#x-4' },
    { text: 'Tasks', to: '#x-5' },
    ];
  }
  else if (location.pathname === '/thepath/build/developing-mental-toughness') {
    stickynavLinks = [
      { text: 'Introduction', to: '#x-0' },
    { text: 'The Foundations of Mental Toughness', to: '#x-1' },
    { text: 'Drawing Inspiration', to: '#x-2' },
    { text: 'Practical Steps', to: '#x-3' },
    { text: 'Conclusion', to: '#x-4' },

    { text: 'Tasks', to: '#x-5' },
    ];
  }
  else if (location.pathname === '/thepath/build/building-emotional-intelligence') {
    stickynavLinks = [
      { text: 'Introduction', to: '#x-0' },
    { text: 'Understanding Emotional Intelligence', to: '#x-1' },
    { text: 'Developing Emotional Intelligence', to: '#x-2' },
    { text: 'The Masculine Perspective', to: '#x-3' },
    { text: 'Conclusion', to: '#x-4' },

    ];
  }
  else if (location.pathname === '/thepath/build/improving-communication-skills') {
    stickynavLinks = [
      { text: 'The Significance of Communication', to: '#x-0' },
    { text: 'Components of Effective Communication', to: '#x-1' },
    { text: 'Developing Strong Communication Skills', to: '#x-2' },
    { text: 'Masculinity and Effective Communication', to: '#x-3' },
    { text: 'Conclusion', to: '#x-4' },

    ];
  }
  else if (location.pathname === '/thepath/build/cultivating-relationships-and-connections') {
    stickynavLinks = [
      { text: 'The Power of Relationships', to: '#x-0' },
    { text: 'Cultivating Masculine Connections', to: '#x-1' },
    { text: 'The Masculine Network', to: '#x-2' },
    { text: 'Cultivating Your Masculine Network', to: '#x-3' },
    { text: 'The Masculine Perspective', to: '#x-4' },
    { text: 'Conclusion', to: '#x-5' },
    { text: 'Tasks', to: '#x-6' },

    ];
  } else if (location.pathname === '/thepath/build/effective-communication-skills') {
    stickynavLinks = [
      { text: 'The Essence of Masculine Communication', to: '#x-1' },
      { text: 'Components of Masculine Communication', to: '#x-2' },
      { text: 'Mastering the Art of Conversation', to: '#x-3' },
      { text: 'The Power of Silence', to: '#x-4' },
      { text: 'Non-Verbal Communication', to: '#x-5' },
      { text: 'Harnessing Communication in Leadership', to: '#x-6' },
      { text: 'Challenges in Communication', to: '#x-7' },
      { text: 'Conclusion', to: '#x-8' },
      { text: 'Tasks', to: '#x-9' },
    ];
}   else if (location.pathname === '/thepath/build/goal-setting-and-achievement') {
  stickynavLinks = [
    { text: 'Understanding Masculine Goal Setting', to: '#x-1' },
    { text: 'The Process of Masculine Goal Setting', to: '#x-2' },
    { text: 'Masculine Values and Goal Categories', to: '#x-3' },
    { text: 'Achieving Masculine Goals', to: '#x-4' },
    { text: 'Conclusion', to: '#x-5' },
  ];
}   else if (location.pathname === '/thepath/build/conflict-resolution-and-effective-communication') {
  stickynavLinks = [
    { text: 'Effective Communication in Conflict Resolution', to: '#x-1' },
    { text: 'Understanding the Dynamics of Conflict', to: '#x-2' },
    { text: 'Active Listening and Empathy', to: '#x-3' },
    { text: 'Assertiveness and Setting Boundaries', to: '#x-4' },
    { text: 'De-Escalation Techniques', to: '#x-5' },
    { text: 'Mediation and Seeking Professional Help', to: '#x-6' },
    { text: 'Conclusion', to: '#x-7' },
  ];
}  else if (location.pathname === '/thepath/build/building-confidence-and-self-esteem') {
  stickynavLinks = [
    { text: 'Building Confidence and Self-Esteem', to: '#x-0' },
    { text: 'Understanding Confidence', to: '#x-1' },
    { text: 'Practical Steps to Build Confidence', to: '#x-2' },
    { text: 'Nurturing Self-Esteem', to: '#x-3' },
    { text: 'Practical Steps to Enhance Self-Esteem', to: '#x-4' },
    { text: 'Conclusion', to: '#x-5' },
    { text: 'Tasks', to: '#x-6' },
  ];
}   else if (location.pathname === '/thepath/build/leadership-skills-development') {
  stickynavLinks = [
    { text: 'Understanding Leadership', to: '#x-1' },
    { text: 'Developing Leadership Skills', to: '#x-2' },
    { text: 'Leadership in Action', to: '#x-3' },
    { text: 'Leading Through Challenges', to: '#x-4' },
    { text: 'Conclusion', to: '#x-5' },
    { text: 'Tasks', to: '#x-6' },
  ];
}   else if (location.pathname === '/thepath/build/building-resilient-relationships') {
  stickynavLinks = [
    { text: 'The Importance of Resilient Relationships', to: '#x-1' },
    { text: 'Key Components of Resilient Relationships', to: '#x-2' },
    { text: 'Cultivating Resilience in Relationships', to: '#x-3' },
    { text: 'Challenges in Building Resilient Relationships', to: '#x-4' },
    { text: '"The Way of Men" by Jack Donovan', to: '#x-5' },
    { text: 'Conclusion', to: '#x-6' },
    { text: 'Tasks', to: '#x-7' },
  ];
}




 else if (location.pathname === '/thepath/build/enhancing-decision-making-skills') {
  stickynavLinks = [
    { text: 'The Essence of Decision Making', to: '#x-0' },
    { text: 'Frameworks for Decision Making', to: '#x-1' },
    { text: 'Impediments to Effective Decision Making', to: '#x-2' },
    { text: 'Embracing Stoicism in Decision Making', to: '#x-3' },
    { text: 'The Role of Intuition', to: '#x-4' },
    { text: 'The Masculine Approach to Decision Making', to: '#x-5' },
    { text: 'Practical Application', to: '#x-6' },
    { text: 'Conclusion', to: '#x-7' },
    { text: 'Tasks', to: '#x-8' },
  ];
} // Check the current path to determine which lesson is being viewed
if (location.pathname === '/thepath/build/stress-management-and-resilience') {
  stickynavLinks = [
    { text: 'Understanding Stress and Resilience', to: '#x-0' },
    { text: 'Identifying Stressors', to: '#x-1' },
    { text: 'Healthy Coping Mechanisms', to: '#x-2' },
    { text: 'Building Resilience', to: '#x-3' },
    { text: 'Seeking Support', to: '#x-4' },
    { text: 'Creating a Stress-Resilience Plan', to: '#x-5' },
    { text: 'Conclusion', to: '#x-6' },
  ];
}



  
  else if (location.pathname === '/thepath/empower/identifying-and-challenging-limiting-beliefs') {
    stickynavLinks = [
      { text: 'Understanding Limiting Beliefs', to: '#x-0' },
      { text: 'Recognizing Your Limiting Beliefs', to: '#x-1' },
      { text: 'The Origin of Limiting Beliefs', to: '#x-2' },
      { text: 'Challenging Your Limiting Beliefs', to: '#x-3' },
      { text: 'Replacing Limiting Beliefs with Empowering Ones', to: '#x-4' },
      { text: 'The Role of Self-Compassion', to: '#x-5' },
      { text: 'Taking Action', to: '#x-6' },
      { text: 'Embracing a Growth Mindset', to: '#x-7' },
      { text: 'Conclusion', to: '#x-8' },
    ];
  }
  else if (location.pathname === '/thepath/empower/developing-a-growth-oriented-attitude') {
    stickynavLinks = [
      { text: 'Introduction to Growth-Oriented Attitude', to: '#x-0' },
      { text: 'Understanding Fixed vs. Growth Mindset', to: '#x-1' },
      { text: 'Embracing Challenges and Learning', to: '#x-2' },
      { text: 'Cultivating Resilience', to: '#x-3' },
      { text: 'The Power of Positive Self-Talk', to: '#x-4' },
      { text: 'Setting and Pursuing Meaningful Goals', to: '#x-5' },
      { text: 'Seeking Opportunities for Self-Improvement', to: '#x-6' },
      { text: 'Embracing Change and Adaptability', to: '#x-7' },
      { text: 'Conclusion', to: '#x-8' },
      { text: 'Tasks', to: '#x-9' },

    ];
  } else if (location.pathname === '/thepath/empower/fostering-a-growth-mindset') {
    stickynavLinks = [
      { text: 'The Power of a Growth Mindset', to: '#x-0' },
      { text: 'Understanding the Growth Mindset', to: '#x-1' },
      { text: 'Embracing Challenges', to: '#x-2' },
      { text: 'Learning from Failure', to: '#x-3' },
      { text: 'The Importance of Effort', to: '#x-4' },
      { text: 'Cultivating a Growth Mindset', to: '#x-5' },
      { text: 'Tasks', to: '#x-6' },
    ];
  }  else if (location.pathname === '/thepath/empower/personal-branding-and-image') {
    stickynavLinks = [
      { text: 'Crafting Your Personal Brand', to: '#x-0' },
      { text: 'Dress for Success', to: '#x-1' },
      { text: 'Effective Communication and Presence', to: '#x-2' },
      { text: 'Online and Social Media Presence', to: '#x-3' },
      { text: 'Building Your Network', to: '#x-4' },
      { text: 'Maintaining Consistency', to: '#x-5' },
      { text: 'Conclusion', to: '#x-6' },
      { text: 'Tasks and Further Reading', to: '#x-7' },
    ];
  }
  else if (location.pathname === '/thepath/empower/building-a-support-system-for-growth-and-empowerment') {
    stickynavLinks = [
      { text: 'The Importance of a Support System', to: '#x-0' },
      { text: 'Identifying Your Support Network', to: '#x-1' },
      { text: 'The Role of Mentors and Guides', to: '#x-2' },
      { text: 'Reciprocity and Contribution', to: '#x-3' },
      { text: 'Nurturing Positive Relationships', to: '#x-4' },
      { text: 'Diversity in Your Support System', to: '#x-5' },
      { text: 'Task', to: '#x-6' },
    ];
  } else if (location.pathname === '/thepath/empower/mindfulness-and-meditation') {
    stickynavLinks = [
      { text: 'Exploring Mindfulness and Meditation', to: '#x-0' },
      { text: 'Understanding Mindfulness', to: '#x-1' },
      { text: 'Unlocking the Profound Benefits of Meditation', to: '#x-2' },
      { text: 'Practical Applications of Mindfulness and Meditation', to: '#x-3' }
    ];
  } 
  else if (location.pathname === '/thepath/empower/practicing-self-care-and-self-love') {
    stickynavLinks = [
      { text: 'Introduction', to: '#x-0' },
      { text: 'Understanding Self-Care', to: '#x-1' },
      { text: 'The Importance of Self-Love', to: '#x-2' },
      { text: 'Incorporating Self-Care into Your Routine', to: '#x-3' },
      { text: 'Practical Steps for Cultivating Self-Love', to: '#x-4' },
      { text: 'Balancing Self-Care and Empowerment', to: '#x-5' },
      { text: 'Conclusion', to: '#x-6' },
    ];
  } else if (location.pathname === '/thepath/empower/empowering-others-and-creating-a-positive-impact') {
    stickynavLinks = [
      { text: 'Introduction', to: '#x-0' },
      { text: 'The Masculine Perspective on Empowerment', to: '#x-1' },
      { text: 'The Power of Leading by Example', to: '#x-2' },
      { text: 'Providing Mentorship and Guidance', to: '#x-3' },
      { text: 'Creating Positive Impact in the Community', to: '#x-4' },
      { text: 'Empowering Through Communication', to: '#x-5' },
      { text: 'Conclusion', to: '#x-6' },
    ]; 
  } else if (location.pathname === '/thepath/reflect/assessing-personal-growth-and-progress') {
    stickynavLinks = [
      { text: 'Introduction', to: '#x-0' },
      { text: 'Why Assessing Personal Growth Matters', to: '#x-1' },
      { text: 'Setting Clear and Measurable Goals', to: '#x-2' },
      { text: 'Tracking Your Progress', to: '#x-3' },
      { text: 'Assessing Different Areas of Your Life', to: '#x-4' },
      { text: 'The Importance of Celebrating Wins', to: '#x-5' },
      { text: 'Adjusting Your Course', to: '#x-6' },
      { text: 'Conclusion', to: '#x-7' },
    ];
  }   else if (location.pathname === '/thepath/reflect/evaluating-current-habits-and-goals') {
    stickynavLinks = [
      { text: 'Reflecting on Progress', to: '#x-0' },
      { text: 'Understanding Your Habits', to: '#x-1' },
      { text: 'Reassessing Your Goals', to: '#x-2' },
      { text: 'Identifying Beneficial Habits', to: '#x-3' },
      { text: 'Addressing Detrimental Habits', to: '#x-4' },
      { text: 'Creating a Reflection Plan', to: '#x-5' },
      { text: 'Celebrating Your Progress', to: '#x-6' },
      { text: 'Conclusion', to: '#x-7' },
    ];
  }
  else if (location.pathname === '/thepath/build/mastering-time-management') {
    stickynavLinks = [
      { text: 'The Key to Productivity and Success', to: '#x-0' },
      { text: 'Setting Clear Priorities', to: '#x-1' },
      { text: 'Creating a Time Management System', to: '#x-2' },
      { text: 'Overcoming Procrastination', to: '#x-3' },
      { text: 'Balancing Work and Life', to: '#x-4' },
      { text: 'Continuous Improvement', to: '#x-5' },
      { text: 'Conclusion', to: '#x-6' },
    ];
  }
  
  
  
  

  
  
  
  
  
  
  
  

  
  
  useEffect(() => {
    const handleScroll = () => {
      if (primarySection) {

        const rect = primarySection.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView) {
          const stickynavLinksEl = document.querySelectorAll('.sticky-nav a');
          stickynavLinksEl.forEach(el => {
            if (el.getAttribute('href') === `#${primarySection.id}`) {
              el.classList.remove('unactive');
            } else {

              el.classList.add('unactive');
            }
          });
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [primarySection]);


  return (
    <div className='hideMobile' style={{margin: '60px 0px 0px 0px'}}>
    <div style={{margin: '0px', height: '94%'}}>
    <div className="sticky-nav">
      <ul>
        {stickynavLinks.map(link => (
          <li key={link.text}>
            <a className={'unactive'} href={link.to} onClick={handleNavClick}>{link.text}</a>
          </li>
        ))}
      </ul>
    </div>
    </div>
    </div>
  );
};

export default StickyNav;
