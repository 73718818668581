import React from 'react';
import { Link } from 'react-router-dom';

const StartJourney = () => (
  
  <div id="StartJourney" className='StartJourneyHome'>
    <div className='SJ_container'>
      <div className='sj_padding'>
        <h2 className='sj_SM' >Stop Mediocrity</h2>
        <p className='sj_text'>Don't waste another day. Begin the journey towards <span className='second_font' style={{fontWeight: '600'}}>THE REAL YOU</span> who <span className='second_font' style={{fontWeight: '600'}}>You Are Meant To Be.</span></p>
      </div>
      <Link  to='/thepath' className='fill'><button className="button sj_button" onClick={() => window.scrollTo(0, 0)}
 >Unlock My Full Potential</button></Link>
    </div>
  </div>
);

export default StartJourney;
