import React from 'react';

const Dropdown = () => {
  return (
    <svg id="dropdown_icon" width="24" height="24" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_702_12142)">
      <mask id="mask0_702_12142" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M24 0H0V24H24V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_702_12142)">
        <path id="dropdown_color" d="M4.80005 8.39996L10.8 14.4L16.8 8.39996H4.80005Z" fill="#FFFFFF" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_702_12142">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
}

export default Dropdown;
