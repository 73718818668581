import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../img/logo/TMR_logo_ws.png'
import { enc, AES } from 'crypto-js';

require('dotenv').config({ path: '../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;
const crypto = require('crypto');


  
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}
function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerFullyExtended, setIsDrawerFullyExtended] = useState(false);

  function handleDrawerTransitionEnd() {
    setIsDrawerFullyExtended(true);
  }

  function handleCloseDrawer() {
    setIsOpen(false);
    setIsDrawerFullyExtended(false);
  }

  function handleOpenDrawer() {
    setIsOpen(true);
    setTimeout(() => {
      setIsDrawerFullyExtended(true);
    }, 150);
  }

  useEffect(() => {
    // add overflow: hidden to body when drawer is open
    if (isOpen) {
        document.body.style.overflowX = 'hidden';
        document.body.style.position = 'fixed';
      } else {
        document.body.style.overflowX = 'hidden';
        document.body.style.position = 'unset';
      }
    }, [isOpen]);

    let loggedInUser = localStorage.getItem('loggedInUser');
    let loggedInEmail = localStorage.getItem('loggedInEmail');
  
    const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
    const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  
    loggedInUser = decryptedUsername;
    loggedInEmail = decryptedEmail;
  return (
    <div className="hamburger-menu">
      <button className={`hamburger-menu__button ${isOpen ? 'open' : ''}`} onClick={() => handleOpenDrawer()}>
        <span className="hamburger-menu__icon"></span>
      </button>
      <nav className={`hamburger-menu__nav ${isOpen ? 'open' : ''}`}>
        
      </nav>
      {isOpen && (
        <Drawer isOpen={isOpen} isDrawerFullyExtended={isDrawerFullyExtended} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleCloseDrawer} loggedInEmail={loggedInEmail} />
      )}
    </div>
  );
}

function Drawer(props) {
    function handleCloseDrawer() {
      props.onClose();
    }

    function handleLogin() {
        const currentUrl = window.location.pathname;
        if (currentUrl !== '/api/signup' && currentUrl !== '/api/login') {
          localStorage.setItem('prevUrl', currentUrl); // Store the current URL as prevUrl
        }
        if (currentUrl == '/'){
        localStorage.setItem('prevUrl', '/thepath'); // Store the current URL as prevUrl
        }
        // ...
      }

    function handleLogout() {
        localStorage.removeItem('loggedInUser');
        localStorage.removeItem('loggedInEmail');
    
         if (window.location.pathname === '/profile') {
        window.location.href = '/';
         }
         else{
        window.location.reload();}
      }

      function handleLoginAndCloseDrawer() {
        handleLogin();
        handleCloseDrawer();
      }
  
    let loggedInUser = localStorage.getItem('loggedInUser');
    let loggedInEmail = localStorage.getItem('loggedInEmail');
  
    const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
    const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;
  
    loggedInUser = decryptedUsername;
    loggedInEmail = decryptedEmail;
  
    return (
      <div className={`drawer ${props.isOpen ? 'open' : ''}`} onTransitionEnd={props.onTransitionEnd}>
        <button className={`drawer__close ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}></button>
        <nav className={`drawer__nav ${props.isOpen ? 'open' : ''}`}>
          {loggedInEmail ? (
            <><ul className="drawer__list">
            <div>
              <li className='center sixteenpxpadbot'>
                <Link to="/">
                  <div className={`logo opacity0 ${props.isDrawerFullyExtended ? 'open__item' : ''}`}>
                    <img src={image} alt="logo" width="200px" height="auto" />
                  </div>
                </Link>
              </li>
              
              <li className="drawer__item">
                <Link to='/thepath' className={`drawer__link  primary-button ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>The Path</Link>
              </li>
              <li className="drawer__item">
                  <Link to='/knowledge-center' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Knowledge Center</Link>
                </li>
              <li className="drawer__item">
                <Link to='/profile' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>{loggedInUser}'s Profile</Link>
              </li>
              <li className="drawer__item">
                <Link to='/ourmission' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Our Mission</Link>
              </li>
        
              </div>
              
              <div className={`userInfo ${props.isDrawerFullyExtended ? 'open__item' : ''}`}>
              <li className='centerUsername' ><p className={`user-email ${props.isDrawerFullyExtended ? 'open__item' : ''}`}>Logged in as: </p><p style={{fontWeight: '700', fontSize: '18px'}}>{loggedInEmail}</p></li>
              <li className="drawer__item">
                <button className={`drawer__link logout-button center ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleLogout}>Log out</button>
              </li>
            </div>
          </ul>
            
            </>
          ) : (
            <ul className="drawer__list">
              <div>
                <li className='center'>
                  <Link to="/">
                    <div className={`logo opacity0 ${props.isDrawerFullyExtended ? 'open__item' : ''}`}>
                      <img src={image} alt="logo" width="200px" height="auto" />
                    </div>
                  </Link>
                </li>
                <li className="drawer__item">
                  <Link to='/' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Home</Link>
                </li>
                <li className="drawer__item">
                  <Link to='/thepath' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>The Path</Link>
                </li>
                <li className="drawer__item">
                  <Link to='/knowledge-center' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Knowledge Center</Link>
                </li>
                <li className="drawer__item">
                  <Link to='/ourmission' className={`drawer__link ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Our Mission</Link>
                </li>
             
              </div>
              <div>
                <li className="drawer__item">
                  <Link to='/api/signup' className={`drawer__link drawer__link__blue ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleCloseDrawer}>Sign up</Link>
                </li>
                <div className='center margTop48'>
                  <span className={`center opacity0 ${props.isDrawerFullyExtended ? 'open__item' : ''}`}>Already have an account?</span>
                  <li className="drawer__item center">
                    <Link to='/api/login' className={`drawer__link drawer__link2 ${props.isDrawerFullyExtended ? 'open__item' : ''}`} onClick={handleLoginAndCloseDrawer}>Log In</Link>
                  </li>
                </div>
              </div>
            </ul>
          )}
        </nav>
      </div>
    );
  }
  
      
      export default HamburgerMenu;