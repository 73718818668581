import React, { useState, useEffect } from 'react';
import axios from 'axios';

function MarkCompleteButton({ path, loggedInEmail }) {
  const [completed, setCompleted] = useState(false);
  const lessonId = path.split('/').pop();

  useEffect(() => {
    if (loggedInEmail) {
      axios.get(`${process.env.REACT_APP_SERVER_URL}/api/getCompletedLessons`, { params: { email: loggedInEmail } })
        .then((response) => {
          const completedLessons = response.data;

          setCompleted(completedLessons.includes(lessonId));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [lessonId, loggedInEmail]);


  const handleMarkComplete = () => {
    if (!loggedInEmail) {
      const confirmLogin = window.confirm('You must be logged in to mark a lesson as complete. Click "OK" to sign up.');
      if (confirmLogin) {
        window.location.href = '/api/signup';
      }
      return;
    }
  
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/markComplete`, { lessonId, email: loggedInEmail })
      .then(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/getCompletedLessons`, { params: { email: loggedInEmail } })
          .then((response) => {
            const completedLessons = response.data;
            setCompleted(completedLessons.includes(lessonId));
            console.log(`Lesson ${lessonId} updated.`);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  return (
    <button id={completed ? 'completed_lesson_button' : 'uncompleted_lesson_button'} className={completed ? 'btn button lesson_button' : 'btn button lesson_button uncompleted_lesson_button'} onClick={handleMarkComplete}>{completed ? 'Completed!' : 'Mark Complete'}
        
        <div className={completed ? 'completed_lesson_icon' : 'uncompleted_lesson_icon'}></div>

    </button>
  );
}

export default MarkCompleteButton;