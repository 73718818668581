import React, { useState, useEffect, useRef } from 'react'; // Import 'useRef'
import YoutubeEmbed from './YoutubeEmbed';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import StripeComponent from './Stripe/StripeComponent';
import { enc, AES } from 'crypto-js';
import BlogItem from './BlogItem';
import StartJourney from './StartJourney';
import Footer from './Footer';
import TwitterTimeline from './TwitterTimeline';



const BlogsList = () => {
  const location = useLocation();
  var [lesson, setLesson] = useState(''); // Set initial value to an empty string
  const [category, setCategory] = useState('');


  



  


  useEffect(() => {
    const path = location.pathname;
    const parts = path.split('/');

    if (parts.length >= 3) {
      setCategory(parts[2]);
      setLesson(parts[3]);
    }

  }, [location.pathname, lesson, category]);
  
  const capitalizeFirstLetter = (string) => {
    const prepositions = ['in', 'on', 'at', 'to', 'of', 'with', 'by', 'for', 'from', 'up', 'down', 'off', 'over', 'out', 'about', 'the', 'a', 'from', 'and'];
    const words = string.split(' ');
    const capitalizedWords = words.map((word, index) => {
      if (index === 0 || !prepositions.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    });
    return capitalizedWords.join(' ');
  }



  const encryptionKey = process.env.REACT_APP_SECRET_KEY;

  function decryptData(data) {
    const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
    return decrypted;
  }


  const [isPremium, setIsPremium] = useState(false);

  let loggedInUser = localStorage.getItem('loggedInUser');
let loggedInEmail = localStorage.getItem('loggedInEmail');

const decryptedUsername = loggedInUser ? decryptData(loggedInUser) : null;
const decryptedEmail = loggedInEmail ? decryptData(loggedInEmail) : null;

loggedInUser = decryptedUsername;
loggedInEmail = decryptedEmail;




  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/isUserPremium`, { params: { email: decryptedEmail } });
      setIsPremium(response.data.isPremium);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (decryptedEmail) {
      fetchUserData();
    }// eslint-disable-next-line
  }, [decryptedEmail]);


useEffect(() => {
  if (category && category !== 'introduction' && category !== 'awaken' && category !== 'build' && loggedInEmail && !isPremium) {
    window.location.href = "/getpremium"; // redirect to success page
  }// eslint-disable-next-line
}, [isPremium]);



  console.log(lesson);
  console.log(category);
  console.log(isPremium)


   const [blogsToShow, setBlogsToShow] = useState(9); // Number of blogs to show initially
  const [blogs, setBlogs] = useState([
{
    id: "extreme-ownership",
    title: 'Extreme Ownership: Taking Charge of Your Life',
    opening: 'In "Extreme Ownership" by Jocko Willink and Leif Babin, the authors explore the concept of extreme ownership as a life philosophy. This book offers valuable insights on how to take full responsibility for every aspect of life, both personal and professional. Join us in this enlightening journey as we delve into the key principles and learn how to apply extreme ownership to everyday situations.',
    image: './img/blog_img/extreme_ownership.jpg', // Replace with actual image URL
    url: 'https://themasculinereset.com/knowledge-center/extreme-ownership',
    datePublished: '2023-12-15',
  },
    {
      id: "interview-with-the-devil",
    title: 'Unlocking Success: A Provocative Conversation with the Devil',
    opening: 'In "Outwitting the Devil" by Napoleon Hill, the author engages in a thought-provoking conversation with the Devil himself. This imaginary interview delves into the strategies and tactics the Devil uses to hold individuals back from achieving success, happiness, and fulfillment. Join us in this eye-opening lesson as we explore the key takeaways and insights from Hill\'s daring dialogue with the Devil.',
    image: './img/blog_img/interview-with-the-devil.jpg', // Replace with actual image URL
    url: 'https://themasculinereset.com/knowledge-center/interview-with-the-devil',
    datePublished: '2023-11-30',
    },
    
    {
      id: '12-rules-for-life',
      title: '12 Rules for Life: An Antidote to Chaos - A Guide to a Meaningful Life',
      opening: 'Life can be a chaotic and unpredictable journey, filled with challenges and uncertainty. In his book "12 Rules for Life: An Antidote to Chaos," Jordan Peterson offers a guiding light—a set of principles and rules to help individuals navigate the complexities of existence and find meaning in their lives.',
      image: './img/blog_img/12_rules_for_life.jpg', // Replace with actual image URL
    },
    {
      id: "the-science-of-sleep",
      title: "The Science of Sleep: Optimizing Rest for Peak Performance",
      opening: "In the quest for peak performance, individuals often focus on aspects like diet, exercise, and time management. While these factors are undoubtedly important, one critical component often gets overlooked: sleep. Understanding the science of sleep and optimizing your rest can be a game-changer when it comes to achieving peak performance in your personal and professional life.",
      image: "./img/blog_img/sleep-man.jpg", // Replace with actual image URL
      },
    {
      id: 'exploring-stoic-wisdom:-practical-insights-for-modern-men',
      title: 'Exploring Stoic Wisdom: Practical Insights for Modern Men',
      opening: 'Stoicism, an ancient philosophy founded in Athens, Greece, has endured through centuries and continues to offer valuable wisdom for navigating the complexities of modern life. In this comprehensive exploration, we delve into the practical insights that Stoic philosophy provides for men seeking clarity, resilience, and tranquility in the face of life’s challenges. Discover how Stoicism\'s timeless principles, championed by notable figures like Marcus Aurelius and Epictetus, can empower you to lead a more purposeful and fulfilling life.',
      image: './img/blog_img/stoicism.jpg', // Replace with actual image URL
    },
    
    {
      id: "journey-to-masculine-excellence", // Unique ID for the new blog
      title: 'The Journey to Masculine Excellence: A Roadmap',
      opening: 'Embarking on the path to masculine excellence is a transformative journey that promises personal growth, self-discovery, and a fulfilling life. In this blog, we will outline a roadmap for this extraordinary journey, guiding you through the key milestones and principles that will empower you to become the best version of yourself as a man. Prepare to embark on a profound expedition towards realizing your full potential and embracing the qualities that define true masculinity.',
      image: './img/blog_img/man-in-water.jpg', // Replace with actual image URL
    },
    {
      id: "building-lasting-confidence", // Unique ID for the new blog
      title: 'Building Lasting Confidence: Tips and Strategies',
      opening: 'Confidence is a foundational trait that empowers men to navigate life with resilience and self-assuredness. In this blog, we will delve into the art of building lasting confidence, drawing inspiration from influential figures such as Napoleon Hill and David Goggins. Learn practical tips and strategies to boost your self-confidence, overcome self-doubt, and embark on a journey of self-assured masculinity.',
      image: './img/blog_img/pushup-man.jpg', // Replace with actual image URL
    }, 
    {
      id: 'leadership-skills',
      title: 'Leadership Skills Every Man Should Develop',
      opening: 'Leadership is a fundamental trait that defines a man\'s ability to guide and inspire others towards a common goal. Whether you aspire to lead in your career, community, or personal life, developing essential leadership skills is key to your journey to masculine excellence.',
      image: './img/blog_img/man-standing-dressup-up.jpg', // Replace with actual image URL
    },
    {
      id: 'the-power-of-mindfulness:-a-guide-for-men',
      title: 'The Power of Mindfulness: A Guide for Men',
      opening: 'Dive into the world of mindfulness and discover how this ancient practice can enhance your mental clarity, emotional well-being, and overall quality of life as a man. Learn practical techniques to cultivate mindfulness and apply it to your daily routines for greater self-awareness and inner peace.',
      image: './img/blog_img/pexels-pavel-danilyuk-7519011.jpg' // Replace with actual image URL
    },
    {
      id: 'mastering-resilience:-thriving-in-the-face-of-adversity',
      title: 'Mastering Resilience: Thriving in the Face of Adversity',
      opening: 'Resilience is a core trait that empowers men to bounce back from challenges and setbacks stronger than ever. Explore the principles of resilience and gain insights into how to develop unwavering mental and emotional strength. Discover real-life stories of resilience and apply these lessons to your own life.',
      image: './img/blog_img/pexels-julia-larson-6455950.jpg' // Replace with actual image URL
    },
    {
      id: 'unlocking-the-secrets-of-peak-performance',
      title: 'Unlocking the Secrets of Peak Performance',
      opening: 'Peak performance is not an accident; it\'s a deliberate pursuit of excellence. Uncover the secrets of peak performance that have been embraced by successful men throughout history. Learn how to set and achieve ambitious goals, tap into your full potential, and consistently perform at your best in all aspects of life.',
      image: './img/blog_img/pexels-andrea-piacquadio-3771089.jpg' // Replace with actual image URL
    },
    {
      id: 'the-art-of-effective-communication:-building-stronger-connections',
      title: 'The Art of Effective Communication: Building Stronger Connections',
      opening: 'Effective communication is the cornerstone of meaningful relationships and successful interactions. Delve into the principles of communication that every man should master. Learn how to express yourself clearly, listen actively, and foster deeper connections in both personal and professional settings.',
      image: './img/blog_img/communication.jpg', // Replace with actual image URL
    }, 
    {
      id: 'the-pursuit-of-lifelong-learning:-a-blueprint-for-personal-growth',
      title: 'The Pursuit of Lifelong Learning: A Blueprint for Personal Growth',
      opening: 'Lifelong learning is the pathway to continuous personal growth and development. Explore the benefits of embracing a learning mindset and discover practical strategies for expanding your knowledge, skills, and horizons. Join the ranks of men who prioritize self-improvement through the pursuit of knowledge.',
      image: './img/blog_img/lifelong-learning.jpg', // Replace with actual image URL
    },
    {
      id: 'lessons-from-cant-hurt-me',
      title: "Lessons From Can't Hurt Me",
      opening: "'Can't Hurt Me' by David Goggins is a book that chronicles the author's journey of overcoming extreme challenges to achieve personal growth. Goggins emphasizes the importance of mindset, mental resilience, and taking ownership of one's life. He encourages readers to embrace discomfort, confront adversity, and push beyond self-imposed limits. The book showcases the power of transforming obstacles into opportunities for self-improvement and unleashing one's true potential.",
      image: './img/blog_img/cant-hurt-me.jpg' // Replace with actual image URL

    },
    {
      id: 'financial-wisdome-for-men:-building-wealth-and-security',
      title: 'Financial Wisdom for Men: Building Wealth and Security',
      opening: 'Financial literacy is an essential life skill that empowers men to make informed decisions about money, investments, and financial security. Dive into the world of financial wisdom, learn the principles of wealth building, and gain practical insights to secure your financial future.',
      image: './img/blog_img/financial-wisdom.jpg', // Replace with actual image URL
    },
    {
      id: "unleash-your-inner-strength",
      title: 'Unleash Your Inner Strength: Overcoming Adversity',
      opening: 'Life is a journey filled with both triumphs and tribulations. At times, adversity may seem insurmountable, but within each of us lies an incredible reservoir of inner strength waiting to be unleashed. This blog explores the power of resilience, determination, and self-belief in overcoming life’s challenges and emerging stronger than ever.',
      image: './img/blog_img/adversity.jpg' // Replace with actual image URL
    },
    {
      id: "embracing-wholeness",
      title: 'Embracing Wholeness and Purpose: The Journey Within',
      opening: 'In this transformative exploration, we embark on a journey that delves into the significance of becoming a complete and purpose-driven individual as a man. The path to fulfillment lies in the pursuit of authenticity, embracing your true self, and realizing your unique purpose. Let\'s uncover the profound reasons why evolving into a whole individual matters and how it contributes to not only personal growth but also to creating a positive impact on the world around us.',
      image: './img/logo/TMR_logo.png', // Replace with actual image URL
    },
    
    
    
    
    
    
    
    // Add more blog objects as needed
  ]);
  // State to store the user's search query
  const [searchQuery, setSearchQuery] = useState('');
  const handleLoadMore = () => {
    // Increase the number of blogs to show by 3
    setBlogsToShow(blogsToShow + 9);
  };
  // Function to handle user input in the search field
 
  const filteredBlogs = blogs
  .slice(0, blogsToShow) // First, limit the number of displayed blogs
  .filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.opening.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 250,
      behavior: 'smooth',
    });
  };

  const searchInputRef = useRef(null); // Create a ref for the search input


  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  
    // Check if the search input is populated
    
    if (e.target.value.trim() !== '') {
      if (window.innerWidth < 900) {
        scrollToTop(); // Scroll to the top when the input is populated and screen width is below 900px
      }
    }
  };

  

  useEffect(() => {
    // Add a listener to handle window resize
    const handleResize = () => {
      if (searchQuery.trim() !== '' && window.innerWidth < 900) {
        scrollToTop(); // Scroll to the top when the screen width becomes less than 900px
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [searchQuery]);
  


  // ... (other code)

return (
  <div className="blog-page">
    <div className="blog-center">
      <div className="blog-content-container">
        <h5>{capitalizeFirstLetter(category)}</h5>
        <div>
          <h1 style={{ fontSize: "24px", marginBottom: "12px" }}>
            Knowledge Center
          </h1>
          <h3 style={{ fontSize: "18px", fontWeight: "400" }}>
            Empowering Men with Essential Life Skills and Life Guidance
          </h3>
        </div>
        {/* Search input field */}
        <div className="blog-search-container">
  <div className="input-container">
    <input
  id="blog-search"
  type="text"
  placeholder="Search..."
  value={searchQuery}
  onChange={handleSearchInputChange}
  ref={searchInputRef} // Add the ref here
/>

    {searchQuery && (
      <button
        onClick={() => setSearchQuery('')}
        className="clear-button"
      >
        Clear
      </button>
    )}
  </div>
</div>


        <div className="blog-list">
          {filteredBlogs.length === 0 ? (
            <p>No blogs match the search.</p> // Display this message when no blogs match the search
          ) : (
            // Render the blogs when there are matching ones
            filteredBlogs.slice(0, blogsToShow).map((blog) => (
              <BlogItem
                key={blog.id}
                id={blog.id}
                title={blog.title}
                image={blog.image}
                opening={blog.opening}
                autoFocus={false} // Add this attribute to prevent autofocus

              />
            ))
          )}
        </div>
        {/* "Load More" button */}
        {!searchQuery && blogsToShow < blogs.length && (
          <button
            onClick={handleLoadMore}
            id="blog-load-more-button"
            className="button"
          >
            Load More...
          </button>
        )}

        <StartJourney />
        <TwitterTimeline />

      </div>

    </div>
    <Footer />

  </div>
);

};
export default BlogsList;
