import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { enc, AES } from 'crypto-js';

const crypto = require('crypto');

require('dotenv').config({ path: '../../../.env' });

const encryptionKey = process.env.REACT_APP_SECRET_KEY;


// An encrypt function
function encryptData(text) {
  const encrypted = AES.encrypt(text, encryptionKey).toString();
  return encrypted;
}

// Function to decrypt data
function decryptData(data) {
  const decrypted = AES.decrypt(data, encryptionKey).toString(enc.Utf8);
  return decrypted;
}


class SignupForm extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0); // Scroll to the top of the page

    // Set the initial state
    this.state = {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      signUpSuccess: null, // Add a new state property to track sign-up success
      loading: false // Add a new state property to track loading state

    };
  }


      // Handle changes to the username input
      handleUsernameChange(event) {
        const newUsername = event.target.value;
        if (newUsername.length <= 20) {
          this.setState({ username: newUsername });
        } else {
          alert('Username must be 20 characters or less.');
        }
      }
      

  // Handle form submission
  handleSubmit(event) {
    event.preventDefault();
    const { username, email, password, confirmPassword } = this.state;

    // Check that the passwords match
    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      this.setState({ signUpSuccess: false, errorMessage: 'Passwords do not match' });
      return;
    }

    this.setState({ loading: true });


    // Make an AJAX request to create a new user
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/signup`, { email, password, username })
  .then((response) => {
    console.log('Sign-up successful');
    this.setState({ 
      signUpSuccess: true,
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      loading: false // Set loading state back to false

    }); // Update signUpSuccess state property

    // Log in the user
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login`, { email, password })
      .then((response) => {
        if (response.data.success) {
            console.log('Login successful');
            let prevUrl = localStorage.getItem('prevUrl')

            const encryptedEmail = encryptData(email);
            const encryptedUsername = encryptData(response.data.username);


            localStorage.setItem('loggedInUser', encryptedUsername); // Add username to local storage
            localStorage.setItem('loggedInEmail', encryptedEmail); // Add email to local storage
            const storedEmail = localStorage.getItem('loggedInEmail');
            const storedUsername = localStorage.getItem('loggedInUser');
            const decryptedEmail = decryptData(storedEmail);
            const decryptedUsername = decryptData(storedUsername);

console.log(decryptedEmail); // Outputs: test@example.com
console.log(decryptedUsername); // Outputs: testuser
            this.setState({ loginSuccess: true, loginError: false }); // Update loginSuccess state property
            setTimeout(() => {
                if (prevUrl) {
                    console.log(prevUrl);
                    window.location.href = prevUrl;
                } else {
                    this.props.history.push('/'); // Redirect to default URL if prevUrl is not set or is invalid
                  }
              }, 1000);
        } else {
            console.log('Login failed');
            this.setState({ loginSuccess: false, loginError: response.data.message }); // Update loginError state property
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the login error
      });
  })
  .catch((error) => {
    console.error(error);
    let errorMessage;
    if (error.response.status === 409) {
      errorMessage = 'Email already exists';
      this.setState({ email: '' }); // Clear the email field
    } else {
      errorMessage = 'An error occurred. Please try again later';
    }
    this.setState({ signUpSuccess: false, errorMessage }); // Update signUpSuccess and errorMessage state properties
  });

  }


  // Handle changes to the email input
  // Handle changes to the email input
handleEmailChange(event) {
  const newEmail = event.target.value;
  if (newEmail.length <= 55) {
    this.setState({ 
      email: newEmail, 
      errorMessage: null // Reset the error message state property
    });
  } else {
    alert('Password must be 55 characters or less.');
  }
  
}

  

  // Handle changes to the password input
  handlePasswordChange(event) {
    const password = event.target.value;
    if (password.length <= 20) {
      this.setState({ password: password });
    } else {
      alert('Password must be 20 characters or less.');
    }
  }

  // Handle changes to the confirm password input
  handleConfirmPasswordChange(event) {
    const password = event.target.value;
    if (password.length <= 20) {
      this.setState({ confirmPassword: password });
    } else {
      alert('Confirm password must be 20 characters or less.');
    }
  }
  

  render() {
    // Render a message based on the sign-up success state
    let message;
    let errorMessage;
    const submitButtonText = this.state.loading ? 'Confirming Sign Up...' : 'Submit';

      
    if (this.state.signUpSuccess === true) {
      message = <div className="alert alert-success">Sign-up successful! Navigating to The Path.</div>;
      setTimeout(() => {
        this.setState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        signUpSuccess: null, // Add a new state property to track sign-up success
        errorMessage: null // Reset the error message state property


      });
      window.location.href = '/thepath';

    }, 1000);

    } else if (this.state.signUpSuccess === false) {
      message = <div className="alert alert-danger">Sign-up failed. Please try again.</div>;
      errorMessage = <div className="alert alert-danger">{this.state.errorMessage}</div>;    }


    return (

        <div className='All-Wrap' style={{height: '100vh'}}>
        <div className='signupLogin-wrap'>
          <form className='form-wrap' onSubmit={event => this.handleSubmit(event)}>
            <h1 style={{fontSize: '32px', margin: '0px'}}>Sign Up</h1>
            <div className='loginGroup'>
            <div className="form-group">
          <label htmlFor="username">Username*</label>
          <input type="text" className="form-control" id="username" placeholder="Enter username" value={this.state.username} onChange={event => this.handleUsernameChange(event)} required/>
        </div>
            <div className="form-group">
              <label htmlFor="email">Email address*</label>
              <input type="email" className={`form-control ${this.state.errorMessage === 'Email already exists' ? 'is-invalid' : ''}`} id="email" placeholder="Enter email" value={this.state.email} onChange={event => this.handleEmailChange(event)} required/>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password*</label>
              <input type="password" className="form-control" id="password" placeholder="Enter Password" value={this.state.password} onChange={event => this.handlePasswordChange(event)} required />
            </div>
            <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password*</label>
            <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={(event) => this.handleConfirmPasswordChange(event)} required/>
          </div>
            <button type="submit" className="btn btn-primary submit-button" disabled={this.state.loading}>{submitButtonText}</button>
            {errorMessage}
            {message}
            </div>
            <Link className="signupLink" to='/api/login'>Or log in to your current account</Link>

          </form>

        </div>
        </div>
    );
  }
}

export default SignupForm;
