import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import './App.css';
import Mindset from "./pages/mindset";
import Home from "./pages/home";
import About from "./pages/about";
import Mastery from "./pages/mastery";
import OurMission from "./pages/OurMission";
import ThePath from "./pages/thepath";
import StickyNav from './components/StickyNav';
import Lessons from './components/Lessons';
import LoginForm from './components/log_in/LoginForm';
import SignupForm from './components/log_in/SignupForm';
import PasswordChangeForm from './components/log_in/PasswordChangeForm';
import Profile from './pages/profile';
import LessonPage from './pages/lesson_page';
import Stripe from './components/Stripe/Stripe';
import GetPremium from './pages/GetPremium';
import Payment from './components/Stripe/Payment';
import Completion from './components/Stripe/Completion';
import Terms_Of_Use from './pages/terms_of_use';
import BlogPage from './pages/blog_page';
import BlogsList from './components/BlogsList';
import { Helmet } from "react-helmet-async";
import NotFoundPage from './pages/NotFoundPage';
import { HelmetProvider } from 'react-helmet-async';
require('dotenv').config();

const section1 = [
  { id: 'introduction', title: 'Introduction', href: '#introduction' },
  { id: 'section-1', title: 'Section 1', href: '#section-1' },
  { id: 'section-2', title: 'Section 2', href: '#section-2' },
  { id: 'section-3', title: 'Section 3', href: '#section-3' },
  { id: 'conclusion', title: 'Conclusion', href: '#conclusion' },
];

// Get the current URL path
const currentUrl = window.location.pathname;
console.log("Current URL:", currentUrl);

// Split the URL path into segments
const urlSegments = currentUrl.split("/");
console.log("URL Segments:", urlSegments);

// Get the last segment of the URL (i.e., the last part of the path)
const lastPathSegment = urlSegments[urlSegments.length - 1];
console.log("Last Path Segment:", lastPathSegment);

// Define the base URL for images
const baseUrl = "https://themasculinereset.com/img/blog_img";
console.log("Base URL:", baseUrl);

// Construct the og:image URL based on the last path segment
const ogImageUrl = `${baseUrl}/${lastPathSegment}.jpg`;
console.log("og:image URL:", ogImageUrl);


function App() {



  return (
    <HelmetProvider>
    <Router>

      <div className="App">
        <Header />
        
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path={process.env.REACT_APP_ROUTE_MINDSET} element={<Mindset />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/mastery" element={<Mastery />} />
          <Route exact path="/ourmission" element={<OurMission />} />
          <Route exact path="/thepath" element={<ThePath />} />
          <Route exact path="/thepath/lesssons" element={<StickyNav sections={section1}/>} />
          <Route exact path="/thepath/recognizing-the-desire-for-change" element={<Lessons/>} />
          <Route exact path="/api/login" element={<LoginForm />} />
          <Route exact path="/api/signup" element={<SignupForm />} />
          <Route exact path="/api/changepassword" element={<PasswordChangeForm />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/getpremium" element={<GetPremium />} />
          <Route exact path="/knowledge-center" element={<BlogsList />} />
          <Route path="/knowledge-center/:id" element={<BlogPage />} />


          <Route exact path="/thepath/introduction/the-masculine-reset:-what-it-is-and-why-it-matters" element={<LessonPage />} />
          <Route exact path="/thepath/introduction/getting-the-most-out-of-this-course" element={<LessonPage />} />
          <Route exact path="/thepath/introduction/setting-personal-expectations-and-goals" element={<LessonPage />} />
          <Route exact path="/thepath/introduction/joining-a-community-of-men-who-share-your-goals-and-values" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/the-crisis-of-masculinity:-exploring-the-challenges-facing-men-today" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/the-necessity-of-reclaiming-masculinity" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/recognizing-the-need-for-change" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/understanding-the-societal-and-cultural-impact-on-masculinity" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/examining-current-beliefs-and-attitudes" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/recognizing-how-men-are-taught-to-drift-through-life" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/evaluating-current-level-of-self-discipline" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/an-honest-look-at-yourself" element={<LessonPage />} />
          <Route exact path="/thepath/awaken/setting-the-foundation-for-growth" element={<LessonPage />} />
          <Route exact path="/thepath/aim/defining-personal-goals-and-objectives" element={<LessonPage />} />
          <Route exact path="/thepath/aim/creating-a-personal-mission-statement:-aligning-your-goals-with-your-purpose" element={<LessonPage />} />
          <Route exact path="/thepath/aim/identifying-your-why:-finding-your-motivation" element={<LessonPage />} />
          <Route exact path="/thepath/aim/the-power-of-dopamine:-feeling-motivated" element={<LessonPage />} />
          <Route exact path="/thepath/aim/understanding-the-different-types-of-masculine-men" element={<LessonPage />} />
          <Route exact path="/thepath/aim/overcoming-procrastination:-building-momentum-and-productivity" element={<LessonPage />} />
          <Route exact path="/thepath/aim/defining-your-masculine-identity" element={<LessonPage />} />
          <Route exact path="/thepath/aim/creating-your-vision-of-masculine-excellence" element={<LessonPage />} />


          <Route exact path="/thepath/aim/developing-a-positive-mental-attitude" element={<LessonPage />} />
          <Route exact path="/thepath/aim/achieving-success-through-persistence" element={<LessonPage />} />
          <Route exact path="/thepath/build/establishing-healthy-habits" element={<LessonPage />} />
          <Route exact path="/thepath/build/improving-physical-health-and-wellness" element={<LessonPage />} />
          <Route exact path="/thepath/build/developing-mental-toughness" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-emotional-intelligence" element={<LessonPage />} />
          <Route exact path="/thepath/build/improving-communication-skills" element={<LessonPage />} />
          <Route exact path="/thepath/build/cultivating-relationships-and-connections" element={<LessonPage />} />
          <Route exact path="/thepath/build/mastering-time-management" element={<LessonPage />} />
          <Route exact path="/thepath/build/enhancing-decision-making-skills" element={<LessonPage />} />
          <Route exact path="/thepath/build/stress-management-and-resilience" element={<LessonPage />} />
          <Route exact path="/thepath/build/goal-setting-and-achievement" element={<LessonPage />} />
          <Route exact path="/thepath/build/conflict-resolution-and-effective-communication" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-healthy-habits-for-success" element={<LessonPage />} />
          <Route exact path="/thepath/build/leadership-skills-development" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-resilient-relationships" element={<LessonPage />} />
          <Route exact path="/thepath/build/effective-communication-skills" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-confidence-and-self-esteem" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-leadership-skills-development" element={<LessonPage />} />
          <Route exact path="/thepath/build/building-building-resilient-relationships" element={<LessonPage />} />

          <Route exact path="/thepath/empower/fostering-a-growth-mindset" element={<LessonPage />} />
          <Route exact path="/thepath/empower/identifying-and-challenging-limiting-beliefs" element={<LessonPage />} />
          <Route exact path="/thepath/empower/mindfulness-and-meditation" element={<LessonPage />} />
          <Route exact path="/thepath/empower/personal-branding-and-image" element={<LessonPage />} />
          <Route exact path="/thepath/empower/building-a-support-system-for-growth-and-empowerment" element={<LessonPage />} />
          <Route exact path="/thepath/empower/practicing-self-care-and-self-love" element={<LessonPage />} />
          <Route exact path="/thepath/empower/empowering-others-and-creating-a-positive-impact" element={<LessonPage />} />
          <Route exact path="/thepath/reflect/assessing-personal-growth-and-progress" element={<LessonPage />} />
          <Route exact path="/thepath/empower/developing-a-growth-oriented-attitude" element={<LessonPage />} />
          <Route exact path="/thepath/reflect/evaluating-current-habits-and-goals" element={<LessonPage />} />


          <Route exact path="/terms_of_use" element={<Terms_Of_Use />}/>

          <Route exact path="/payment" element={<Payment />}/>
          <Route exact path="/completion" element={<Completion />}/>
          <Route path="*" element={<NotFoundPage />} /> {/* Handle 404 errors */}


        </Routes>
        
        <Helmet>
          <title>The Masculine Reset</title>
          <meta name="description" content="Reclaim your purpose and achieve your potential to be with The Masculine Reset. Discover proven methods used by great men to develop strength, character, and discipline." />
          <meta name="author" content="David Wheeler" />
          <meta name="keywords" content="The Masculine Reset, Masculinity, Masculine, Reclaim Your Purpose, Become a Man, Becoming a Man, Fulfilling Your Potential, Self-Improvement, Personal Development, Men's Transformation, Character Development, Self-Discipline, High Character, Self-Empowerment, Men's Empowerment, Confidence, Motivation, Inner Strength, Self-Worth, Self-Esteem, Personal Growth, Self-Discovery, Resilience, Independence, Financial Independence, Wealth Building, Success Mindset, Goal Setting, Mindfulness, Mental Toughness, Emotional Intelligence, Building Strong Relationships, Communication Skills, Leadership Skills, Life Purpose, Fulfillment, Healthy Lifestyle, Well-being, Positive Impact, Life Transformation, Manhood, Men's Health, Life Skills, Empowering Men, Motivation for Men, Self-Actualization, How to become a confident man, Masculine traits and characteristics, Building self-confidence for men, Developing masculinity in young men, Self-improvement tips for young men, Becoming a better man guide, Masculinity and self-esteem, Boosting self-worth and self-assurance, Personal growth for young men, Self-confidence exercises for males, Leadership skills for young men, Overcoming insecurity as a young man, Developing assertiveness and self-belief, Confidence-building techniques for guys, Enhancing self-image and self-respect, Mentorship and personal development for males, Building resilience and mental toughness, Effective communication skills for men, Confidence in dating and relationships, Self-discipline and goal setting for young men" />
          <meta name="twitter:site" content="@MasculineReset" />
          <meta name="twitter:title" content="Reclaim your purpose and become the man you were meant to be." />
          <meta name="twitter:description" content="Unlock your full potential, embrace self-improvement, and become the best version of yourself. Join us on a journey of transformation and empowerment." />
          <link rel="canonical" href="https://themasculinereset.com" />
          <link rel="icon" href="img/logo/TMR_logo_ws.png" type="image/png" />
          <link rel="shortcut icon" href="img/logo/TMR_logo_ws.png" type="image/png"/> 
          <link rel="icon" href="https://themasculinereset.com/img/logo/TMR_logo_ws.png" type="image/x-icon"/>

        </Helmet>

     </div>

      </Router>
      </HelmetProvider>
  );
}

export default App;
